import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyEmailAndSetPassword } from '../../api/apiHandlers';

const AdminEmailVerification = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    // Extract the token from the URL query parameters
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const token = query.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);

        if (!token) {
            setError("Verification token is missing.");
            return;
        }

        try {
            await verifyEmailAndSetPassword(token, password);
            setSuccess(true);
            setPassword('');
            navigate('/');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="container">
            <h2 className="header">Verify Email and Set Password</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>Your email has been verified and password set successfully.</p>}
            <form className="modal-form" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="password">New Password:</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Verify Email</button>
            </form>
        </div>
    );
};

export default AdminEmailVerification;
