import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import { fetchExerciseHistory, fetchUserVolume } from '../../api/apiHandlers';
import { format, parseISO } from 'date-fns';

// Register chart.js plugins
Chart.register(...registerables);



// ========= BodyMetrics Chart ============
const BodyMetricsChart = ({ dates, weightData, bodyFatMassData, bodyFatPercentageData, leanBodyMassData }) => {
    const [selectedMetric, setSelectedMetric] = useState('Weight');


    const createChartData = (label, data, borderColor) => ({
        labels: dates,
        datasets: [{ label, data, borderColor, fill: false }]
    });

    const chartOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM dd, yyyy',
                    },
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                type: 'linear',
                title: {
                    display: false,
                    text: 'Value'
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false
            }
        }
    };


    const renderChart = () => {
        switch (selectedMetric) {
            case 'Weight':
                return <Line data={createChartData('Weight: lbs', weightData, 'blue')} options={chartOptions} />;
            case 'Body Fat Mass':
                return <Line data={createChartData('Body Fat Mass: lbs', bodyFatMassData, 'green')} options={chartOptions} />;
            case 'Body Fat Percentage':
                return <Line data={createChartData('Body Fat Percentage: %', bodyFatPercentageData, 'red')} options={chartOptions} />;
            case 'Lean Body Mass':
                return <Line data={createChartData('Lean Body Mass: lbs', leanBodyMassData, 'purple')} options={chartOptions} />;
            default:
                return null;
        }
    };

    return (
        <div>
            <select value={selectedMetric} onChange={(e) => setSelectedMetric(e.target.value)}>
                <option value="Weight">Weight</option>
                <option value="Body Fat Mass">Body Fat Mass</option>
                <option value="Body Fat Percentage">Body Fat Percentage</option>
                <option value="Lean Body Mass">Lean Body Mass</option>
            </select>
            <div className="chart-container">
                {renderChart()}
            </div>
        </div>
    );
};

export { BodyMetricsChart };


// ========= Exercise Volume Chart ============

const ExerciseVolumeChart = ({ userId, exerciseId }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Daily Volume',
            data: [],
            borderColor: 'blue',
            fill: false,
        }],
    });

    const handleClick = (event) => {
        const points = chartRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        if (points.length) {
            const firstPoint = points[0];
            const label = chartRef.current.data.labels[firstPoint.index];
            const value = chartRef.current.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

            // Now you can use 'label' and 'value' to perform the desired action.
            console.log(label, value);
        }
    };

    // Create a reference to the chart instance
    const chartRef = useRef(null);

    // Fetch exercise history data and process it for charting
    useEffect(() => {
        const fetchData = async () => {
            try {
                const historyData = await fetchExerciseHistory(userId, exerciseId);
                const processedData = processExerciseData(historyData);
                setChartData({
                    labels: processedData.labels,
                    datasets: [{
                        label: 'Daily Volume',
                        data: processedData.volumes,
                        borderColor: 'blue',
                        fill: false,
                    }]
                });
            } catch (error) {
                console.error('Error fetching exercise data:', error);
            }
        };

        fetchData();
    }, [userId, exerciseId]);

    // Process the exercise data to create the chart data
    const processExerciseData = (data) => {
        const volumeByDate = {};

        data.forEach(entry => {
            const date = format(parseISO(entry.Date), 'yyyy-MM-dd');
            const volume = entry.Reps * parseFloat(entry.Weight);
            if (volume > 0) {
                volumeByDate[date] = (volumeByDate[date] || 0) + volume;
            }
        });

        const labels = Object.keys(volumeByDate).filter(date => volumeByDate[date] > 0).sort((a, b) => new Date(a) - new Date(b));
        const volumes = labels.map(date => volumeByDate[date]);

        return { labels, volumes };
    };

    // Chart Options
    const chartOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM dd, yyyy',
                    },
                    tooltipFormat: 'MMM dd, yyyy',
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    // Function to filter the ticks after they are built
                    afterBuildTicks: axis => {
                        // Use the labels from the chartData state
                        const dataLabels = chartData.labels;
                        // Filter out the axis ticks to only include the ones that match your data labels
                        axis.ticks = axis.ticks.filter(tick => dataLabels.includes(tick.value));
                    }
                }
            },
            y: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Volume'
                }
            }
        },
        onClick: handleClick,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false
            }
        }
    };

    return (
        <div>
            <div className="chart-container">
                <Line ref={chartRef} data={chartData} options={chartOptions} />
            </div>
        </div>
    );
};

export { ExerciseVolumeChart };