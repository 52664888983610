import React, { useState, useEffect } from 'react';
import { createNewExercise, fetchMuscleGroups, fetchExerciseEquipment } from '../../api/apiHandlers';

const CreateExerciseModal = ({ isCreateExerciseModalOpen, toggleCreateExerciseModal, onModalClose }) => {
    // Hooks called unconditionally at the top
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [newExerciseData, setNewExerciseData] = useState({
        name: '',
        description: '',
        trackTime: false,
        trackDistance: false,
        trackWeight: false,
        trackReps: false,
        isUnilateral: false,
        isBilateral: false,
        primaryMuscleGroup: '',
        assistingMuscleGroup: '',
        isCardio: false,
        equipment: ''
    });

    // Load muscle groups on render
    useEffect(() => {
        if (!isCreateExerciseModalOpen) return;

        const getMuscleGroups = async () => {
            setIsLoading(true);
            try {
                const muscleGroups = await fetchMuscleGroups();
                setMuscleGroups(muscleGroups);
            } catch (error) {
                console.error('Failed to fetch muscle groups:', error);
            }
            setIsLoading(false);
        };

        getMuscleGroups();
    }, [isCreateExerciseModalOpen]);

    // Load equipment on render
    useEffect(() => {
        if (!isCreateExerciseModalOpen) return;

        const getEquipment = async () => {
            setIsLoading(true);
            try {
                const equipment = await fetchExerciseEquipment();
                setEquipment(equipment);
            } catch (error) {
                console.error('Failed to fetch equipment:', error);
            }
            setIsLoading(false);
        };

        getEquipment();
    }, [isCreateExerciseModalOpen]);


    // Retrieve TrainerID on Render
    const retrieveTrainerId = () => {
        const trainerUserId = parseInt(localStorage.getItem('userId'), 10);
        return trainerUserId;
    }

    // Retrieve TrainerID on Render
    useEffect(() => {
        const trainerId = retrieveTrainerId();
    }, []);



    // Conditional Return
    if (!isCreateExerciseModalOpen) {
        return null;
    }


    const handleCreateNewExercise = async (event) => {
        event.preventDefault();

        try {
            const trainerId = localStorage.getItem('userId');

            const exerciseDataToSend = {
                name: newExerciseData.name,
                description: newExerciseData.description,
                tracksTime: newExerciseData.trackTime,
                tracksDistance: newExerciseData.trackDistance,
                tracksWeight: newExerciseData.trackWeight,
                tracksReps: newExerciseData.trackReps,
                isUnilateral: newExerciseData.isUnilateral,
                isBilateral: newExerciseData.isBilateral,
                primaryMuscleGroupID: newExerciseData.primaryMuscleGroupID, // Updated to match backend
                assistingMuscleGroupID: newExerciseData.assistingMuscleGroupID, // Updated to match backend
                isCardio: newExerciseData.isCardio,
                equipmentID: newExerciseData.equipmentID, // Updated to match backend
                trainerId: trainerId
            };
            
            await createNewExercise(exerciseDataToSend);

            // Close modal and reload exercises
            toggleCreateExerciseModal();
            onModalClose(); // Call the passed function to reload exercises
        } catch (error) {
            console.error('Error creating new exercise:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    // Close modal and reload exercises
    const closeModal = () => {
        toggleCreateExerciseModal();
        onModalClose(); // Also call onModalClose here to ensure exercises are reloaded even if the modal is closed without creating an exercise
    };


    // Checkbox onChange handlers
    const handleCheckboxChange = (property, value) => {
        setNewExerciseData(prevData => ({
            ...prevData,
            [property]: value
        }));
    };




    return (
        <div className="modal">
            <div className="modal-content">
                <button className="modal-close-btn" onClick={closeModal}>×</button>
                <form className="modal-form" onSubmit={handleCreateNewExercise}>
                    <input
                        type="text"
                        placeholder="Exercise Name"
                        onChange={(e) => setNewExerciseData({ ...newExerciseData, name: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        onChange={(e) => setNewExerciseData({ ...newExerciseData, description: e.target.value })}
                    />
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackTime', e.target.checked)}
                            /> Track Time
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackDistance', e.target.checked)}
                            /> Track Distance
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackWeight', e.target.checked)}
                            /> Track Weight
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackReps', e.target.checked)}
                            /> Track Reps
                        </label>
                    </div>
                    <select
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                equipmentID: e.target.value  // This will now be the EquipmentID
                            });
                        }}
                    >
                        <option value="">Exercise Equipment...</option>
                        {equipment.map((equipmentItem) => (
                            <option key={equipmentItem.EquipmentID} value={equipmentItem.EquipmentID}>
                                {equipmentItem.EquipmentName}
                            </option>
                        ))}
                    </select>

                    <select
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                isUnilateral: e.target.value === 'unilateral',
                                isBilateral: e.target.value === 'bilateral',
                                isCardio: e.target.value === 'cardio'
                            });
                        }}
                    >
                        <option value="">Exercise Focus...</option>
                        <option value="unilateral">Unilateral</option>
                        <option value="bilateral">Bilateral</option>
                        <option value="cardio">Cardio</option>
                    </select>

                    <select
                        // Example onChange handler for a select input
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                primaryMuscleGroupID: e.target.value
                            });
                        }}
                    >
                        <option value="">Primary Muscle Group...</option>
                        {muscleGroups.map((muscleGroup) => (
                            <option key={muscleGroup.MuscleGroupID} value={muscleGroup.MuscleGroupID}>
                                {muscleGroup.MuscleGroupName}
                            </option>
                        ))}
                    </select>

                    <select
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                assistingMuscleGroupID: e.target.value  // This will now be the MuscleGroupID
                            });
                        }}
                    >
                        <option value="">Assisting Muscle Group...</option>
                        {muscleGroups.map((muscleGroup) => (
                            <option key={muscleGroup.MuscleGroupID} value={muscleGroup.MuscleGroupID}>
                                {muscleGroup.MuscleGroupName}
                            </option>
                        ))}
                    </select>

                    <button type="submit" className="button" onClick={handleCreateNewExercise}>Create Exercise</button>
                    <button type="button" className="button" onClick={toggleCreateExerciseModal}>Cancel</button>
                </form>
            </div>
        </div>
    )
};

export default CreateExerciseModal;