import React, { useEffect, useState } from 'react';
import { fetchMuscleGroups, fetchExerciseEquipment, updateExercise } from '../../api/apiHandlers';


const ExerciseDetailsModal = ({
    isDetailsModalOpen,
    toggleDetailsModal,
    selectedExercise,
    onModalClose,
    onExerciseUpdated
}) => {

    const [muscleGroups, setMuscleGroups] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [editing, setEditing] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editableExercise, setEditableExercise] = useState(null);
    const wasFetched = selectedExercise ? selectedExercise.wasFetched : false;

    // Add this useEffect hook to log the selectedExercise any time it changes
    useEffect(() => {
        console.log('Received Exercise Details:', selectedExercise);
    }, [selectedExercise]);

    useEffect(() => {
        
    }, [editableExercise]);

    console.log('Exercise was fetched:', wasFetched);
    


    // Fetch Muscle Groups
    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const fetchedMuscleGroups = await fetchMuscleGroups();
                setMuscleGroups(fetchedMuscleGroups); // Update the state with fetched muscle groups
            } catch (error) {
                console.error("Failed to fetch muscle groups:", error);
            }
        };
        
        fetchGroups();
    }, []);

    // Fetch Equipment
    useEffect(() => {
        const fetchEquipment = async () => {
            try {
                const fetchedEquipment = await fetchExerciseEquipment();
                
                setEquipment(fetchedEquipment);
            } catch (error) {
                console.error("Failed to fetch equipment:", error);
            }
        };
        
        fetchEquipment();
    }, []);

    useEffect(() => {
        // Reset editableExercise any time the selectedExercise changes
        setEditableExercise(selectedExercise);
        setIsEditMode(false); // Optionally, you might want to exit edit mode when a new exercise is selected
    }, [selectedExercise]);


    const handleEditClick = () => {
        if (!editableExercise && selectedExercise) {
            setEditableExercise(selectedExercise);
        }
        setIsEditMode(true);
    };

    useEffect(() => {
        if (selectedExercise) {
            const enrichedExercise = {
                ...selectedExercise,
                EquipmentID: equipment.find(e => e.EquipmentName === selectedExercise.EquipmentName)?.EquipmentID || '',
                PrimaryMuscleGroupID: muscleGroups.find(mg => mg.MuscleGroupName === selectedExercise.PrimaryMuscleGroupName)?.MuscleGroupID || '',
                AssistingMuscleGroupID: muscleGroups.find(mg => mg.MuscleGroupName === selectedExercise.AssistingMuscleGroupName)?.MuscleGroupID || ''
            };
            
            setEditableExercise(enrichedExercise);
            setIsEditMode(false); // Optionally, reset edit mode state
        }
    }, [selectedExercise, muscleGroups, equipment]);
    



    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === "checkbox") {
            // For regular checkboxes, convert boolean checked state to 1 or 0
            setEditableExercise({ ...editableExercise, [name]: checked ? 1 : 0 });
        } else if (name === "UnilateralBilateralCardio") {
            // Assuming the input for Exercise Focus has name "UnilateralBilateralCardio" and value "Unilateral", "Bilateral", or "Cardio"
            // Reset all to 0
            let updatedExercise = {
                ...editableExercise,
                isUnilateral: 0,
                isBilateral: 0,
                isCardio: 0
            };

            // Set the selected focus to 1
            switch (value) {
                case "Unilateral":
                    updatedExercise.isUnilateral = 1;
                    break;
                case "Bilateral":
                    updatedExercise.isBilateral = 1;
                    break;
                case "Cardio":
                    updatedExercise.isCardio = 1;
                    break;
                default:
                    // In case of an unexpected value, log an error or handle appropriately
                    console.error(`Unexpected value for Exercise Focus: ${value}`);
                    break;
            }
            setEditableExercise(updatedExercise);
        } else {
            // For all other inputs, just save the value
            setEditableExercise(prev => ({ ...prev, [name]: value || prev[name] }));
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        
    
        const toNullIfEmpty = value => value === '' ? null : value;
    
        try {
            // Construct the exercise data to match the database schema
            const exerciseToUpdate = {
                Name: editableExercise.Name,
                Description: editableExercise.Description || null, // Use null for an empty description
                TracksTime: editableExercise.TracksTime ? 1 : null,
                TracksDistance: editableExercise.TracksDistance ? 1 : null,
                TracksWeight: editableExercise.TracksWeight ? 1 : null,
                TracksReps: editableExercise.TracksReps ? 1 : null,
                isUnilateral: editableExercise.isUnilateral || 0, // Use 0 as default if undefined
                isBilateral: editableExercise.isBilateral || 0,
                isCardio: editableExercise.isCardio || 0,
                PrimaryMuscleGroupID: toNullIfEmpty(editableExercise.PrimaryMuscleGroupID),
                AssistingMuscleGroupID: toNullIfEmpty(editableExercise.AssistingMuscleGroupID),
                EquipmentID: toNullIfEmpty(editableExercise.EquipmentID),
                TrainerUserID: selectedExercise.TrainerUserID,
            };
            await updateExercise(selectedExercise.ExerciseID, exerciseToUpdate);

            onExerciseUpdated(editableExercise);
    
            setIsEditMode(false);
            onModalClose();
        } catch (error) {
            console.error("Failed to update exercise:", error);
        }
    };
    


    const determineExerciseFocus = (exercise) => {
        if (exercise.isCardio) return 'Cardio';
        if (exercise.isUnilateral) return 'Unilateral';
        if (exercise.isBilateral) return 'Bilateral';
        return null; // default value if none is set
    };

    const handleEquipmentChange = (event) => {
        const selectedEquipmentID = event.target.value;
        setEditableExercise(prevState => {
            const updatedState = { ...prevState, EquipmentID: selectedEquipmentID };
            
            return updatedState;
        });
    };

    const handlePrimaryMuscleGroupChange = (event) => {
        const selectedGroupID = event.target.value;
        setEditableExercise(prevState => {
            const updatedState = { ...prevState, PrimaryMuscleGroupID: selectedGroupID };
            
            return updatedState;
        });
    };

    const handleAssistingMuscleGroupChange = (event) => {
        const selectedGroupID = event.target.value;
        setEditableExercise(prevState => {
            const updatedState = { ...prevState, AssistingMuscleGroupID: selectedGroupID };
            
            return updatedState;
        });
    };



    if (!isDetailsModalOpen) {
        return null;
    }
    return (
        <div>
            {selectedExercise && (
                <div className="modal">
                    <div className="modal-content" style={{ position: 'relative' }}>
                    <button type="button" className="modal-close-btn" onClick={toggleDetailsModal} style={{ position: 'absolute', top: '10px', right: '10px' }}>×</button>
                        {isEditMode ? (
                            <form className="modal-form" onSubmit={handleSubmit}>
                                <div>
                                    {/* Edit Name */}
                                    <label htmlFor="name">Name:</label>
                                    <input type="text" id="name" name="Name" value={editableExercise.Name} onChange={handleInputChange} size="40" />
                                </div>
                                <div>
                                    {/* Edit Description */}
                                    <label htmlFor="description">Notes:</label>
                                    <textarea id="description" name="Description" value={editableExercise.Description} onChange={handleInputChange} cols="40" rows="5" />
                                </div>
                                <div>
                                    {/* Edit TracksTime */}
                                    <label>
                                        <input type="checkbox" id="tracksTime" name="TracksTime" checked={editableExercise.TracksTime} onChange={handleInputChange}
                                        /> Track Time
                                    </label>
                                </div>
                                <div>
                                    {/* Edit TracksDistance */}
                                    <label>
                                        <input type="checkbox" id="tracksDistance" name="TracksDistance" checked={editableExercise.TracksDistance} onChange={handleInputChange}
                                        /> Track Distance
                                    </label>
                                </div>
                                <div>
                                    {/* Edit TracksWeight */}
                                    <label>
                                        <input type="checkbox" id="tracksWeight" name="TracksWeight" checked={editableExercise.TracksWeight} onChange={handleInputChange}
                                        /> Track Weight
                                    </label>
                                </div>
                                <div>
                                    {/* Edit TracksReps */}
                                    <label>
                                        <input type="checkbox" id="tracksReps" name="TracksReps" checked={editableExercise.TracksReps} onChange={handleInputChange}
                                        /> Track Reps
                                    </label>
                                </div>


                                <div>
                                    {/* Exercise Equipment */}
                                    <label htmlFor="equipment">Exercise Equipment:</label>
                                    <select
                                        id="equipment"
                                        name="EquipmentID" // Changed to use ID
                                        value={editableExercise.EquipmentID || ""}
                                        onChange={handleEquipmentChange} // Update this handler to set EquipmentID
                                    >
                                        <option value="">No equipment selected.</option>
                                        {equipment.map((equip, index) => (
                                            <option key={index} value={equip.EquipmentID}>{equip.EquipmentName}</option>
                                        ))}
                                    </select>
                                </div>


                                <div>
                                    {/* Exercise Focus */}
                                    <label htmlFor="unilateralBilateral">Exercise Focus:</label>
                                    <select
                                        id="unilateralBilateral"
                                        name="UnilateralBilateralCardio" // Update this to match the handleInputChange function
                                        value={determineExerciseFocus(editableExercise)}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Unilateral">Unilateral</option>
                                        <option value="Bilateral">Bilateral</option>
                                        <option value="Cardio">Cardio</option>
                                    </select>
                                </div>

                                <div>
                                    {/* Edit PrimaryMuscleGroup */}
                                    <label htmlFor="primaryMuscleGroup">Primary Muscle Group:</label>
                                    <select
                                        id="primaryMuscleGroup"
                                        name="PrimaryMuscleGroupID" // Changed to use ID
                                        value={editableExercise.PrimaryMuscleGroupID || ""}
                                        onChange={handlePrimaryMuscleGroupChange} // Update this handler to set PrimaryMuscleGroupID
                                    >
                                        <option value="">No muscle group selected.</option>
                                        {muscleGroups.map((group, index) => (
                                            <option key={index} value={group.MuscleGroupID}>{group.MuscleGroupName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    {/* Edit AssistingMuscleGroup */}
                                    <label htmlFor="assistingMuscleGroup">Assisting Muscle Group:</label>
                                    <select
                                        id="assistingMuscleGroup"
                                        name="AssistingMuscleGroupID" // Changed to use ID
                                        value={editableExercise.AssistingMuscleGroupID || ""}
                                        onChange={handleAssistingMuscleGroupChange} // Update this handler to set AssistingMuscleGroupID
                                    >
                                        <option value="">No muscle group selected.</option>
                                        {muscleGroups.map((group, index) => (
                                            <option key={index} value={group.MuscleGroupID}>{group.MuscleGroupName}</option>
                                        ))}
                                    </select>
                                </div>
                                <button className="button" type="submit" onClick={handleSubmit}>Update Exercise</button>
                            </form>
                        ) : (
                            <>
                                <div className="exercise-details">
                                    <h2>{editableExercise?.Name}</h2>
                                    <p>Notes: {editableExercise?.Description || "No notes provided"}</p>
                                    {typeof editableExercise?.file_url === 'string' && <img src={editableExercise.file_url} alt={editableExercise.Name} onError={(e)=>{e.target.onerror = null; e.target.src="Path/to/your/default/image.jpg"}} />}
                                </div>
                                {!selectedExercise.wasFetched && <button className="button" onClick={handleEditClick}>Edit</button>}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExerciseDetailsModal;