import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BodyMetricsChart } from './ChartComponents';
import { VolumeChartComponent } from './VolumeChartComponent';
import {
    fetchUserProfile,
    fetchClientBodyMetrics,
    fetchClientAccountDetails,
    addClientBodyMetrics,
    archiveClientProfile,
    createClientLoginAccount
} from '../../api/apiHandlers';


const ClientProfile = () => {
    const [clientProfile, setClientProfile] = useState(null);
    const [bodyMetrics, setBodyMetrics] = useState(null);
    const [accountDetails, setAccountDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { clientId } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [newMetrics, setNewMetrics] = useState({
        Weight: '',
        BodyFatPercentage: '',
        LeanBodyMass: '',
        BodyFatMass: ''
    });
    const [bodyFatPercentageDisplay, setBodyFatPercentageDisplay] = useState('');
    const [bodyFatPercentage, setBodyFatPercentage] = useState('');
    const bodyFatInputRef = useRef(null)
    const [weightDisplay, setWeightDisplay] = useState('');
    const [weight, setWeight] = useState('');
    const weightInputRef = useRef(null);
    const [leanBodyMassDisplay, setLeanBodyMassDisplay] = useState('');
    const [leanBodyMass, setLeanBodyMass] = useState('');
    const leanBodyMassInputRef = useRef(null);
    const [bodyFatMassDisplay, setBodyFatMassDisplay] = useState('');
    const [bodyFatMass, setBodyFatMass] = useState('')
    const bodyFatMassInputRef = useRef(null);
    const [customDate, setCustomDate] = useState(new Date().toISOString().split('T')[0]); // Default to current date
    const navigate = useNavigate();
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
    const handleOpenCreateAccountModal = () => setShowCreateAccountModal(true);
    const handleCloseCreateAccountModal = () => setShowCreateAccountModal(false);
    const [email, setEmail] = useState('');
    const [userType] = useState(localStorage.getItem('userType'));



    // Fetch User Profile
    useEffect(() => {
        let isMounted = true;
        console.log('Client ID:', clientId)

        const fetchData = async () => {
            try {
                const userProfile = await fetchUserProfile(clientId);
                console.log('User Profile:', userProfile);
                setClientProfile(userProfile);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }

            try {
                const bodyMetricsData = await fetchClientBodyMetrics(clientId);
                setBodyMetrics(bodyMetricsData);
            } catch (error) {
                console.error('Error fetching body metrics:', error);
                setBodyMetrics(null); // or setBodyMetrics([]);
            }

            try {
                const accountDetailsData = await fetchClientAccountDetails(clientId);
                setAccountDetails(accountDetailsData);
            } catch (error) {
                console.error('Error fetching account details:', error);
            }

            if (isMounted) {
                setLoading(false);
            }
        };
        fetchData();

        return () => {
            isMounted = false;
        };
    }, [clientId]);



    // Set the weight display value whenever the weight changes
    useEffect(() => {
        if (weightInputRef.current) {
            const length = weight.length;
            weightInputRef.current.setSelectionRange(length, length);
        }
    }, [weight]);

    // Set the body fat percentage display value whenever the body fat percentage changes
    useEffect(() => {
        if (bodyFatInputRef.current) {
            const length = bodyFatPercentage.length;
            bodyFatInputRef.current.setSelectionRange(length, length);
        }
    }, [bodyFatPercentage]);

    // Set the lean body mass display value whenever the lean body mass changes
    useEffect(() => {
        if (leanBodyMassInputRef.current) {
            const length = leanBodyMass.length;
            leanBodyMassInputRef.current.setSelectionRange(length, length);
        }
    }, [leanBodyMass]);

    // Set the body fat mass display value whenever the body fat mass changes
    useEffect(() => {
        if (bodyFatMassInputRef.current) {
            const length = bodyFatMass.length;
            bodyFatMassInputRef.current.setSelectionRange(length, length);
        }
    }, [bodyFatMass]);




    // Helper function to get the most recent metrics
    const getMostRecentMetrics = () => {
        if (bodyMetrics && bodyMetrics.length > 0) {
            const sortedMetrics = [...bodyMetrics].sort((a, b) =>
                new Date(b.DateRecorded) - new Date(a.DateRecorded)
            );
            return sortedMetrics[0];
        }
        return null;
    };
    // Get the most recent metrics
    const mostRecentMetrics = getMostRecentMetrics();


    // Modal Functions
    const handleOpenModal = () => {
        setShowModal(true);
    };

    // Close the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Handle adding new metrics
    const handleAddMetrics = async (event) => {
        event.preventDefault();

        // Parse the string values to float and format them as fixed decimals
        const metricsData = {
            Weight: parseFloat(weight).toFixed(2),
            BodyFatPercentage: parseFloat(bodyFatPercentage).toFixed(2),
            LeanBodyMass: parseFloat(leanBodyMass).toFixed(2),
            BodyFatMass: parseFloat(bodyFatMass).toFixed(2),
            DateRecorded: customDate
        };

        // Send the data to the server
        try {
            await addClientBodyMetrics(clientId, metricsData);
            const updatedMetrics = await fetchClientBodyMetrics(clientId);
            setBodyMetrics(updatedMetrics);
            handleCloseModal();

            // Reset input fields after successful submission
            setWeight('');
            setBodyFatPercentage('');
            setLeanBodyMass('');
            setBodyFatMass('');
            setCustomDate(new Date().toISOString().split('T')[0]);
        } catch (error) {
            console.error('Error adding new metrics:', error);
        }
    };



    // ========= Handle Input Changes and Focus =========

    // Handle the weight input change
    const handleWeightChange = (e) => {
        const value = e.target.value.replace(' lbs', '');
        setWeight(value);
    };
    // Set the cursor position to the end of the input value
    const handleWeightFocus = (e) => {
        const value = e.target.value.replace(' lbs', '');
        setTimeout(() => {
            if (weightInputRef.current && document.activeElement === weightInputRef.current) {
                const length = value.length;
                weightInputRef.current.setSelectionRange(length, length);
            }
        }, 0);
    };


    // Set the body fat percentage display value whenever the body fat percentage changes
    const handleBodyFatChange = (e) => {
        const value = e.target.value.replace('%', '');
        setBodyFatPercentage(value);
    };
    // Set the cursor position to the end of the input value
    const handleBodyFatFocus = (e) => {
        const value = e.target.value.replace('%', '');
        setTimeout(() => {
            if (bodyFatInputRef.current && document.activeElement === bodyFatInputRef.current) {
                const length = value.length;
                bodyFatInputRef.current.setSelectionRange(length, length);
            }
        }, 0);
    };


    // Set the lean body mass display value whenever the lean body mass changes
    const handleLeanBodyMassChange = (e) => {
        const value = e.target.value.replace(' lbs', '');
        setLeanBodyMass(value);
    };
    // Set the cursor position to the end of the input value
    const handleLeanBodyMassFocus = (e) => {
        const value = e.target.value.replace(' lbs', '');
        setTimeout(() => {
            if (leanBodyMassInputRef.current && document.activeElement === leanBodyMassInputRef.current) {
                const length = value.length;
                leanBodyMassInputRef.current.setSelectionRange(length, length);
            }
        }, 0);
    };


    // Set the body fat mass display value whenever the body fat mass changes
    const handleBodyFatMassChange = (e) => {
        const value = e.target.value.replace(' lbs', '');
        setBodyFatMass(value);
    };
    // Set the cursor position to the end of the input value
    const handleBodyFatMassFocus = (e) => {
        const value = e.target.value.replace(' lbs', '');
        setTimeout(() => {
            if (bodyFatMassInputRef.current && document.activeElement === bodyFatMassInputRef.current) {
                const length = value.length;
                bodyFatMassInputRef.current.setSelectionRange(length, length);
            }
        }, 0);
    };



    // ========= Render =========

    // Process bodyMetrics data just before rendering
    let dates, weightData, bodyFatMassData, bodyFatPercentageData, leanBodyMassData;
    if (bodyMetrics) {
        dates = bodyMetrics.map(metric => metric.DateRecorded);
        weightData = bodyMetrics.map(metric => metric.Weight);
        bodyFatMassData = bodyMetrics.map(metric => metric.BodyFatMass);
        bodyFatPercentageData = bodyMetrics.map(metric => metric.BodyFatPercentage);
        leanBodyMassData = bodyMetrics.map(metric => metric.LeanBodyMass);
    }


    // ======== Archive Client Profile ========

    // Function to call the archiveClientProfile API handler
    const handleArchiveClient = async () => {
        try {
            const response = await archiveClientProfile(clientId);
            navigate('/clients');
        } catch (error) {
            // Handle any errors that occur during the archiving process
            console.error('Error archiving client:', error);
            // Display an error message to the user
        }
    };


    // ======= Client Account Creation Modal =======

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCreateAccount = async (event) => {
        event.preventDefault();
        setError('');

        try {
            await createClientLoginAccount(clientId, email);
            handleCloseCreateAccountModal();
        } catch (error) {
            console.error(error);
            setError('Failed to create account');
        }
    };

    console.log('UserType:', userType)



    return (
        <div className="container">
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>Error: {error.message}</div>
            ) : (
                <>
                    <div>
                        <h2 className="header"> {clientProfile.FirstName} {clientProfile.LastName} </h2>

                        {userType !== 'client' && (
                            <button className="button" onClick={handleOpenCreateAccountModal}>Create Client Login Account</button>
                        )}
                        {showCreateAccountModal && (
                            <div className="modal">
                                <div className="modal-content" style={{ position: 'relative' }}>
                                    <button
                                        className="modal-close-btn"
                                        onClick={handleCloseCreateAccountModal}
                                        style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                        ×
                                    </button>
                                    <form onSubmit={handleCreateAccount}>
                                        <h2>Create Client Login Account</h2>
                                        <div>
                                            <label htmlFor="email">Email:</label>
                                            <input type="email" name="email" required onChange={handleEmailChange} />
                                        </div>
                                        <button className="button" type="submit">Submit</button>
                                    </form>
                                </div>
                            </div>
                        )}

                        <h2>Workout Volume</h2>
                        <div className="volume-chart-container">
                            <div className="volume-chart">
                                <VolumeChartComponent userId={clientProfile.UserID} timeframe="weekly" />
                            </div>
                        </div>
                    </div>
                    <div className="body-metrics"> {/* Body metrics styling */}
                        <h2>Body Metrics</h2>
                        {bodyMetrics && mostRecentMetrics ? (
                            <div className="client-item"> {/* Reusing client item/card styling */}
                                <p><strong>Weight:</strong> {mostRecentMetrics.Weight}</p>
                                <p><strong>Body Fat Percentage:</strong> {mostRecentMetrics.BodyFatPercentage}</p>
                                <p><strong>Lean Body Mass:</strong> {mostRecentMetrics.LeanBodyMass}</p>
                                <p><strong>Body Fat Mass:</strong> {mostRecentMetrics.BodyFatMass}</p>
                            </div>
                        ) : (
                            <p>No data recorded.</p>
                        )}
                        <button className="button" onClick={handleOpenModal}>Add New Metrics</button>

                        {showModal && (
                            <div className="modal">
                                <div className="modal-content" style={{ position: 'relative' }}>
                                    <button
                                        className="modal-close-btn"
                                        onClick={handleCloseModal}
                                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                                    >
                                        ×
                                    </button>
                                    <button
                                        className="modal-close-btn"
                                        onClick={handleCloseModal}
                                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                                    >
                                        ×
                                    </button>
                                    <form className='modal-form' onSubmit={handleAddMetrics}>
                                        <h2>Add New Body Metrics</h2>
                                        <div>
                                            <label htmlFor="weight">Weight:</label>
                                            <input
                                                ref={weightInputRef}
                                                type="text"
                                                name="Weight"
                                                value={weight + (weight ? ' lbs' : '')}
                                                onChange={handleWeightChange}
                                                onFocus={handleWeightFocus}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="bodyFatPercentage">Body Fat Percentage:</label>
                                            <input
                                                ref={bodyFatInputRef}
                                                type="text"
                                                name="BodyFatPercentage"
                                                value={bodyFatPercentage + (bodyFatPercentage ? '%' : '')}
                                                onChange={handleBodyFatChange}
                                                onFocus={handleBodyFatFocus}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="leanBodyMass">Lean Body Mass:</label>
                                            <input
                                                ref={leanBodyMassInputRef}
                                                type="text"
                                                name="LeanBodyMass"
                                                value={leanBodyMass + (leanBodyMass ? ' lbs' : '')}
                                                onChange={handleLeanBodyMassChange}
                                                onFocus={handleLeanBodyMassFocus}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="bodyFatMass">Body Fat Mass:</label>
                                            <input
                                                ref={bodyFatMassInputRef}
                                                type="text"
                                                name="BodyFatMass"
                                                value={bodyFatMass + (bodyFatMass ? ' lbs' : '')}
                                                onChange={handleBodyFatMassChange}
                                                onFocus={handleBodyFatMassFocus}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="customDate">Date:</label>
                                            <input
                                                className="date-input" // Added class name here
                                                type="date"
                                                name="customDate"
                                                value={customDate}
                                                onChange={(e) => setCustomDate(e.target.value)}
                                            />
                                        </div>

                                        <button className="button" type="submit">Submit</button>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="body-metrics-chart">
                        <h3>Body Metrics Charts</h3>
                        {bodyMetrics && bodyMetrics.length > 0 ? (
                            <BodyMetricsChart
                                dates={dates}
                                weightData={weightData}
                                bodyFatMassData={bodyFatMassData}
                                bodyFatPercentageData={bodyFatPercentageData}
                                leanBodyMassData={leanBodyMassData}
                            />
                        ) : (
                            <p>No chart data available.</p>
                        )}
                    </div>
                </>
            )}
            <button className="button" onClick={handleArchiveClient}>Archive Client</button>
        </div>
    );
};

export default ClientProfile;