import React, { useState, useEffect } from 'react';
import { fetchClientsProfiles } from '../../api/apiHandlers';
import { useNavigate } from 'react-router-dom';

const ClientScreen = () => {
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();
    const trainerId = localStorage.getItem('userId');


    useEffect(() => {
        const userId = localStorage.getItem('userId');
        
        // Check if userId exists then navigate to ClientWorkoutSessions
        if (userId) {
            navigate(`/client-workout-sessions/${userId}`);
        }
    }, [navigate]);
    
    useEffect(() => {
        const fetchAndSetClients = async () => {
            const TrainerUserID = trainerId
            try {
                const response = await fetchClientsProfiles(TrainerUserID);
                response.sort((a, b) => {
                    const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
                    const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                setClients(response);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };
        fetchAndSetClients();
    }, [trainerId]);


    const navigateToClientWorkoutSessions = (clientId) => {
        navigate(`/client-workout-sessions/${clientId}`); // Update the path as per your routing setup
    };

    const navigateToCreateClient = () => {
        navigate('/create-client'); // Replace with the actual path to your create client screen
    };

    return (
        <div className="container">
            <h1 className="header">Clients</h1>
            <div className="client-list">
                {clients.map(client => (
                    <div
                        key={client.UserID}
                        onClick={() => navigateToClientWorkoutSessions(client.UserID)}
                        className="client-item"
                    >
                        <div className="client-avatar">
                            {/* Placeholder for client avatar */}
                        </div>
                        <div className="client-info">
                            <h2>{client.FirstName} {client.LastName}</h2>
                            {/* Additional client info here */}
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container">
                <button className="button" onClick={navigateToCreateClient}>
                    Create New Client
                </button>
            </div>
        </div>
    );
};

export default ClientScreen;
