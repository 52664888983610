import React, { useState } from 'react';
import { loginUser, loginWithApple, importExercises } from '../api/apiHandlers';
import { useNavigate } from 'react-router-dom';
import mainLogo from '../assets/mainLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import JSONLibrary from './tracking-components/ExerciseData'


const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isImportingExercises, setIsImportingExercises] = useState(false);
    const [sessionToken, setSessionToken] = useState('');


    window.handleLogin = function (token) {
        setSessionToken(token);
        if (token) {
            console.log('Token was received');
        } else {
            console.log('Token was not received');
        }
        loginUser(email, password, token);
    };

    const handleExerciseImport = async (userId) => {
        setIsImportingExercises(false); // Reset the flag
        try {
            const importResponse = await importExercises(userId, JSONLibrary);
            // Check the specific message to set the import flag
            if (importResponse.message === "Exercises do not exist, import process can begin.") {
                setIsImportingExercises(true); // Indicate the import process has started
                console.log('Exercise import process has begun');
            } else {
                // Handle other responses or indicate no import is necessary
                setIsImportingExercises(false);
                console.log(importResponse.message);
            }
        } catch (importError) {
            console.error('Exercise import failed:', importError);
            setIsImportingExercises(false); // Ensure the flag is correctly set even in case of error
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true); // Indicate the start of the login process
        try {
            const response = await loginUser(email, password, sessionToken);

            if (response.success) {
                console.log('Login successful:', response);
                localStorage.setItem('token', response.token);
                localStorage.setItem('userId', response.userId);
                localStorage.setItem('userType', response.userType);

                console.log('Token:', response.token);
                console.log('UserId:', response.userId);
                console.log('UserType:', response.userType);

                // Call handleExerciseImport after a successful login
                await handleExerciseImport(response.userId);

                if (response.userType === 'admin') {
                    navigate('/admin-components/AdminHome');
                } else if (response.userType === 'client') {

                    navigate(`/client-workout-sessions/${response.userId}`);
                } else {
                    navigate('/clients');
                }

                try {
                    console.log('About to post message to Swift');
                    window.webkit.messageHandlers.loginHandler.postMessage('User logged in');
                    console.log('Message posted to Swift');
                } catch (error) {
                    console.error('Error posting message to Swift:', error);
                }
            } else {
                console.log('Login failed with response:', response);
                setError('Login failed. Please try again.');
            }
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            setError('Login failed. Please try again.');
        } finally {
            setLoading(false); // Reset loading state on completion
        }
    };


    window.handleAppleLogin = function (appleId, userId, userType, token) {
        handleAppleLogin(appleId, userId, userType, token);
    };

    const handleAppleLogin = async (appleId, userId, userType, token) => {
        setError(''); // Clear any existing errors
        setLoading(true); // Indicate the start of the Apple login process
        try {
            const data = await loginWithApple(appleId, userId, userType, token);
            console.log('Login successful:', data);

            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            localStorage.setItem('userType', userType);

            // Call handleExerciseImport after a successful login
            await handleExerciseImport(userId);

            if (userType === 'admin') {
                navigate('/admin-components/AdminHome');
            } else {
                navigate('/clients');
            }

            try {
                console.log('About to post message to Swift');
                window.webkit.messageHandlers.loginHandler.postMessage('User logged in with Apple');
                console.log('Message posted to Swift');
            } catch (error) {
                console.error('Error posting message to Swift:', error);
            }
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please try again.');
        } finally {
            setLoading(false); // Reset loading state on completion
        }
    };



    const handleRegisterClick = () => {
        navigate('/register');
    };


    return (
        <div style={styles.loginScreen}>
            <img src={mainLogo} alt="Main Logo" style={{ width: '15rem', marginBottom: '20px' }} />
            {loading ? (
                // Render spinner when loading is true
                <div style={styles.spinner}></div>
            ) : (
                // Render form when not loading
                <form onSubmit={handleSubmit}>
                    <h2>Login</h2>
                    {error && <div style={styles.errorMessage}>{error}</div>}
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <button
                            type="button"
                            onClick={() => {
                                console.log("Attempting to post message for Apple Sign In");
                                window.webkit.messageHandlers.appleLogin.postMessage(null);
                            }}
                            style={styles.appleButton}
                        >
                            <FontAwesomeIcon icon={faApple} style={{ marginRight: '10px' }} /> Sign in with Apple
                        </button>
                        <button
                            type="submit"
                            onClick={() => {
                                console.log("Attempting to post message for Login");
                                window.webkit.messageHandlers.handleLogin.postMessage(null);
                            }}
                            style={styles.button}>Login</button>
                        <button style={styles.button} onClick={handleRegisterClick}>Register</button>
                    </div>
                </form>
            )};
        </div>
    );
};

export default LoginScreen;

const styles = {
    loginScreen: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'transparent',
        overflow: 'hidden'
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        justifyContent: 'center',
    },
    input: {
        width: '200px',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '10px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginBottom: '10px',
        width: '200px', // Add this line to set a fixed width
        height: '44px', // Add this line to set a fixed height
    },
    appleButton: {
        display: 'inline-block',
        padding: '0 16px',
        height: '44px',
        borderRadius: '6px',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '14px',
        lineHeight: '44px',
        textAlign: 'center',
        border: 'none',
        fontFamily: 'Arial, sans-serif',
        cursor: 'pointer',
        boxShadow: '0 0 0 1px #fff, 0 0 0 2px #000',
        width: '200px', // Add this line to set a fixed width
        marginBottom: '10px',
    },
    errorMessage: {
        color: 'red',
        marginBottom: '15px',
    },
    spinner: {
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderTopColor: '#007bff',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        animation: 'spin 1s linear infinite',
    }
};