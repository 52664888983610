import React, { useState, useEffect } from 'react';
import { fetchWorkoutTemplatesForTrainer, deleteWorkoutTemplate } from '../../api/apiHandlers';
import { useNavigate } from 'react-router-dom';

const WorkoutTemplates = () => {
    const [workoutTemplates, setWorkoutTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const trainerId = localStorage.getItem('userId');
    const navigate = useNavigate();


    // Fetch Workoiut Components on Mount
    useEffect(() => {
        const fetchTemplates = async () => {
            setIsLoading(true);
            setError(null);
            try {
                let templates = await fetchWorkoutTemplatesForTrainer(trainerId);
                templates = templates.filter(template => template.ProgramReserved === null);
                setWorkoutTemplates(templates);
                console.log('Templates:', templates);
            } catch (err) {
                setError('Failed to fetch workout templates');
                console.error(err);
            }
            setIsLoading(false);
        };

        fetchTemplates();
    }, []);


    // Button Handlers
    const handleCreate = () => {
        navigate('/template-creation');
    };

    const handleView = templateId => {
        navigate(`/template/${templateId}`);
    };

    const handleDelete = async templateId => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            try {
                await deleteWorkoutTemplate(templateId);
                const updatedTemplates = await fetchWorkoutTemplatesForTrainer(trainerId);
                setWorkoutTemplates(updatedTemplates);
            } catch (error) {
                console.error('Failed to delete workout template:', error);
            }
        };
    };


    return (
        <div>
            <h1>Workout Templates</h1>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                workoutTemplates.length > 0 ? (
                    <ul>
                        {workoutTemplates.map(template => (
                            <li key={template.TemplateID}>
                                {template.Name} - {template.Description}
                                <button onClick={() => handleView(template.TemplateID)}>View Template</button>
                                <button onClick={() => handleDelete(template.TemplateID)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                ) : <p>No workout templates found for this trainer.</p>
            )}
            <button onClick={handleCreate}>Create New Template</button>
        </div>
    );






}

export default WorkoutTemplates;