import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import { fetchUserVolume } from '../../api/apiHandlers';

// Register chart.js plugins
Chart.register(...registerables);


const VolumeChartComponent = ({ userId }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [timeframe, setTimeframe] = useState('weekly');
    const [chartStyle, setChartStyle] = useState({ width: '100%' });
    const [selectedMuscleGroup, setSelectedMuscleGroup] = useState('Chest');



    // Fetch User Volume
    useEffect(() => {
        fetchUserVolume(userId)
            .then(data => {
                console.log('Fetched data:', data); // Log the fetched data
                const currentYear = new Date().getFullYear();

                // Filter sessions for the current year and selected muscle group
                const filteredSessions = data.filter(session => {
                    const sessionYear = new Date(session.Date).getFullYear();
                    return sessionYear === currentYear &&
                        (session.PrimaryMuscleGroup === selectedMuscleGroup ||
                            session.AssistingMuscleGroup === selectedMuscleGroup);
                });

                let volumeData = [];

                if (timeframe === 'weekly') {
                    volumeData = getWeeklyTotalVolume(filteredSessions);
                } else if (timeframe === 'monthly') {
                    volumeData = getMonthlyTotalVolume(filteredSessions);
                } else if (timeframe === 'ytd') {
                    // Process the YTD data correctly
                    // Map each session to a new object containing the Date and TotalVolume (sum of primary and assisting volumes if applicable)
                    volumeData = filteredSessions.reduce((acc, session) => {
                        const date = session.Date.split('T')[0]; // Format date to YYYY-MM-DD for consistency

                        // Calculate primary and assisting volumes, considering only relevant volumes based on the selected muscle group
                        const primaryVolume = session.PrimaryMuscleGroup === selectedMuscleGroup ? parseInt(session.PrimaryMuscleVolume || '0', 10) : 0;
                        const assistingVolume = session.AssistingMuscleGroup === selectedMuscleGroup ? parseInt(session.AssistingMuscleVolume || '0', 10) : 0;

                        // If the session date is already in the accumulator, add to its TotalVolume; otherwise, create a new entry
                        if (acc[date]) {
                            acc[date].TotalVolume += primaryVolume + assistingVolume;
                        } else {
                            acc[date] = {
                                Date: date,
                                TotalVolume: primaryVolume + assistingVolume,
                            };
                        }

                        return acc;
                    }, {});

                    // Convert the accumulator object into an array of values
                    volumeData = Object.values(volumeData).filter(session => session.TotalVolume > 0);
                }

                // Create chart data from the processed volumeData
                setChartData(createChartData(volumeData, timeframe));
            })
            .catch(error => console.error('Error fetching user volume:', error));
    }, [userId, timeframe, selectedMuscleGroup]);




    // Helper function to get the start of the week
    function getStartOfWeek(date) {
        const diff = date.getDay() === 0 ? 6 : date.getDay() - 1; // Adjust if your week start is different
        date.setDate(date.getDate() - diff);
        return new Date(date.setHours(0, 0, 0, 0));
    }

    // Method to get weekly total volume
    function getWeeklyTotalVolume(filteredSessions) {
        const weeklyVolumes = {};
        filteredSessions.forEach(session => {
            const date = new Date(session.Date);
            const weekStart = getStartOfWeek(new Date(date)).toISOString();
            const volume = parseInt(session.PrimaryMuscleVolume) + parseInt(session.AssistingMuscleVolume);
            weeklyVolumes[weekStart] = (weeklyVolumes[weekStart] || 0) + volume;
        });
        return Object.entries(weeklyVolumes).map(([week, totalVolume]) => ({ week, totalVolume }));
    }

    // Helper function to get the start of the month
    function getStartOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    // Method to get monthly total volume
    function getMonthlyTotalVolume(filteredSessions) {
        const monthlyVolumes = {};
        filteredSessions.forEach(session => {
            const date = new Date(session.Date);
            const monthStart = getStartOfMonth(new Date(date)).toISOString();
            const volume = parseInt(session.PrimaryMuscleVolume) + parseInt(session.AssistingMuscleVolume);
            monthlyVolumes[monthStart] = (monthlyVolumes[monthStart] || 0) + volume;
        });
        return Object.entries(monthlyVolumes).map(([month, totalVolume]) => ({ month, totalVolume }));
    }



    // ========= Chart Logic =============

    // Example event handler for selecting a timeframe
    function handleSelectTimeframe(e) {
        setTimeframe(e.target.value);
    }

    // Example event handler for selecting a muscle group
    function handleSelectMuscleGroup(e) {
        setSelectedMuscleGroup(e.target.value);
    }

    // Example implementation of createChartData
    function createChartData(volumeData, timeframe) {
        console.log('Creating chart data for:', timeframe, volumeData); // Log when creating chart data

        if (timeframe === 'ytd') {
            const labels = volumeData.map(item => new Date(item.Date).toISOString().split('T')[0]);
            const data = volumeData.map(item => item.TotalVolume);

            console.log('Data points for YTD:', labels, data); // Log the data points for YTD (if any


            // Ensure that there are data points to plot
            if (labels.length === 0 || data.length === 0) {
                console.error('No data points to plot for YTD');
                return { labels: [], datasets: [] }; // Return an empty chart data structure
            }

            const chartDataStructure = {
                labels,
                datasets: [{
                    label: 'Volume',
                    data,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }]
            };

            console.log('Final chartData object for rendering:', chartDataStructure); // Log the final chart data structure

            return chartDataStructure;
        } else {

            return {
                labels: volumeData.map(item => item.week || item.month),
                datasets: [{
                    label: 'Volume',
                    data: volumeData.map(item => item.totalVolume),
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }]
            };
        }
    };

    // Example implementation of getChartOptions
    function getChartOptions() {
        const options = {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: timeframe === 'weekly' ? 'week' : (timeframe === 'monthly' ? 'month' : 'day'), // Changed 'year' to 'day' for granularity
                        tooltipFormat: 'MMM dd, yyyy' // Added tooltip format for clarity
                    },
                    title: {
                        display: true,
                        text: 'Date'
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Volume'
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false
            },
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: false
                }
            }
        };

        console.log('Chart options used:', options); // Log the chart options
        return options;
    }

    useEffect(() => {
        console.log('ChartData has been set:', chartData);
    }, [chartData]);





    // ========= Chart Component =============
    return (
        <div className="volume-chart-container">
            <select value={timeframe} onChange={handleSelectTimeframe}>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="ytd">YTD</option>
            </select>
            <select value={selectedMuscleGroup} onChange={handleSelectMuscleGroup}> {/* New dropdown menu */}
                <option value="Chest">Chest</option>
                <option value="Upper Back">Upper Back</option>
                <option value="Lats">Lats</option>
                <option value="Shoulders">Shoulders</option>
                <option value="Biceps">Biceps</option>
                <option value="Triceps">Triceps</option>
                <option value="Quadriceps">Quadriceps</option>
                <option value="Glutes">Glutes</option>
                <option value="Hamstrings">Hamstrings</option>
                <option value="Core">Core</option>
                <option value="Calves">Calves</option>
            </select>
                <Line data={chartData} options={getChartOptions()} />
        </div>
    );
};

export { VolumeChartComponent };