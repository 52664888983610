import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createWorkoutTemplate } from '../../api/apiHandlers';


const TemplateCreation = () => {
    const [templateName, setTemplateName] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [trainerId, setTrainerId] = useState(localStorage.getItem('userId'));
    const [clientFacing, setClientFacing] = useState(false);
    const [isUniversal, setIsUniversal] = useState(false);
    const [programReserved, setProgramReserved] = useState(false);
    const location = useLocation();
    const [programId, setProgramId] = useState(location.state?.programId);
    const [scheduleId, setScheduleId] = useState(location.state?.scheduleId);

    console.log('ProgramID:', programId);
    console.log('ScheduleID:', scheduleId);

    // Handler for submitting the form to create a new workout template
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            // Properly structured POST data according to the API expectations
            const postData = {
                name: templateName,
                description: description,
                trainerId: localStorage.getItem('userId'),
                clientFacing: clientFacing,
                isUniversal: isUniversal,
                programReserved: programId
            };
            const response = await createWorkoutTemplate(postData);
            // Assuming response contains the TemplateID after successful creation
            if (response && response.TemplateID) {
                navigate('/select-exercises', { state: { templateId: response.TemplateID, programId: programId, scheduleId: scheduleId } });
            } else {
                throw new Error('Failed to retrieve the TemplateID');
            }
        } catch (err) {
            setError('Failed to create workout template. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };




    return (
        <div>
            <h1>Create New Workout Template</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="templateName">Template Name:</label>
                <input
                    id="templateName"
                    type="text"
                    value={templateName}
                    onChange={e => setTemplateName(e.target.value)}
                    required
                />
                <label htmlFor="description">Description:</label>
                <textarea
                    id="description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    required
                />
                <label htmlFor="clientFacing">Client Facing:</label>
                <input
                    id="clientFacing"
                    type="checkbox"
                    checked={clientFacing}
                    onChange={e => setClientFacing(e.target.checked)}
                />
                <label htmlFor="isUniversal">Universal Template:</label>
                <input
                    id="isUniversal"
                    type="checkbox"
                    checked={isUniversal}
                    onChange={e => setIsUniversal(e.target.checked)}
                />
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Creating...' : 'Create Template'}
                </button>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
}

export default TemplateCreation;