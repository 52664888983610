import React, { useEffect, useState } from 'react';
import { fetchExercises } from '../../api/apiHandlers';
import CreateExerciseModal from './CreateExerciseModal';
import ExerciseDetailsModal from './ExerciseDetailsModal';

const ExerciseLibrary = () => {
    const [exercises, setExercises] = useState([]);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [trainerId, setTrainerId] = useState(null);
    const [isCreateExerciseModalOpen, setIsCreateExerciseModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);


    useEffect(() => {
        console.log('Modal open status:', isDetailsModalOpen);
    }, [isDetailsModalOpen]);



    // Fetch Exercises
    useEffect(() => {
        const getExercises = async () => {
            try {
                // Retrieve the trainer's user ID from localStorage and convert it to a number
                const trainerId = parseInt(localStorage.getItem('userId'), 10);

                // Fetch all exercises for the trainer
                const fetchedExercises = await fetchExercises(trainerId);

                // Filter exercises specific to the trainer and universal exercises
                const filteredExercises = fetchedExercises.filter(exercise =>
                    exercise.TrainerUserID === trainerId || exercise.TrainerUserID === 1);

                // Sort the filtered exercises by name
                const sortedExercises = filteredExercises.sort((a, b) => {
                    const nameA = a.Name.toLowerCase();
                    const nameB = b.Name.toLowerCase();
                    return nameA.localeCompare(nameB);
                });

                // Set the filtered and sorted exercises
                setExercises(sortedExercises);

                // Log the fetched exercises for debugging
                console.log('Fetched exercises:', sortedExercises);
            } catch (error) {
                console.error("Failed to fetch exercises:", error);
            }
        };
        getExercises();
    }, []);



    // ============= Exercise Details Modal =============
    const toggleDetailsModal = (exercise) => {
        setSelectedExercise(exercise); // Set the selected exercise
        setIsDetailsModalOpen(!isDetailsModalOpen); // Toggle the visibility of the modal
        console.log('Selected exercise:', exercise);
    };




    // ============= Create Exercise Modal =============
    // Toggle the Modal State
    const toggleCreateExerciseModal = () => {
        setIsCreateExerciseModalOpen(!isCreateExerciseModalOpen);
    };

    // Reload Exercise Library after closing Modal
    async function handleModalClose() {
        await loadExercises();
    }

    // Load Exercises Again after Modal Closes
    const loadExercises = async () => {
        try {
            // Retrieve the trainer's user ID from localStorage and convert it to a number
            const trainerUserId = parseInt(localStorage.getItem('userId'), 10);

            // Fetch all exercises
            const fetchedExercises = await fetchExercises();

            // Filter exercises specific to the trainer and universal exercises
            const filteredExercises = fetchedExercises.filter(exercise =>
                exercise.TrainerUserID === trainerUserId || exercise.TrainerUserID === 1);

            // Sort the filtered exercises by name
            const sortedExercises = filteredExercises.sort((a, b) => {
                const nameA = a.Name.toLowerCase();
                const nameB = b.Name.toLowerCase();
                return nameA.localeCompare(nameB);
            });

            // Set the filtered and sorted exercises
            setExercises(sortedExercises);

            // Log the fetched exercises for debugging
            console.log('Fetched exercises:', sortedExercises);
        } catch (error) {
            console.error("Failed to fetch exercises:", error);
        }
    };




    // ============= Render =============

    return (
        <div>
            <h1>Exercise Library</h1>
            <button className="button" onClick={toggleCreateExerciseModal}>Create New Exercise</button>
            <ul>
                {exercises.map((exercise) => (
                    <li key={exercise.ExerciseID} onClick={() => toggleDetailsModal(exercise)} className="exercise-item">
                        <h2>{exercise.Name}</h2>
                    </li>
                ))}
            </ul>

            <CreateExerciseModal
                isCreateExerciseModalOpen={isCreateExerciseModalOpen}
                toggleCreateExerciseModal={toggleCreateExerciseModal}
                onModalClose={handleModalClose}
            />

            <ExerciseDetailsModal
                isDetailsModalOpen={isDetailsModalOpen}
                toggleDetailsModal={toggleDetailsModal}
                selectedExercise={selectedExercise}
                onModalClose={handleModalClose}
            />


        </div>
    );
};

export default ExerciseLibrary;