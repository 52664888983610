import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    fetchProgramSchedule,
    addProgramDetail,
    fetchWorkoutTemplatesForTrainer
} from '../../api/apiHandlers';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const ProgramDetails = () => {
    const location = useLocation();
    const scheduleId = location.state?.scheduleId;
    const [program, setProgram] = useState(null);
    const [programId, setProgramId] = useState(location.state?.programId);
    const [loading, setLoading] = useState(true);
    const [selectedTemplates, setSelectedTemplates] = useState({});
    const [selectedDays, setSelectedDays] = useState({});
    const [templates, setTemplates] = useState([]);
    const [programReservedTemplates, setProgramReservedTemplates] = useState([]);
    const [universalTemplates, setUniversalTemplates] = useState([]);
    const navigate = useNavigate();

    console.log('ProgramID:', programId);
    console.log('ScheduleID:', scheduleId);

    useEffect(() => {
        const fetchProgram = async () => {
            if (!scheduleId) {
                console.error('scheduleId is not defined');
                return;
            }
            try {
                const fetchedSchedule = await fetchProgramSchedule(scheduleId);
                if (!fetchedSchedule.length) {
                    console.error('Fetched schedule is an empty array');
                    setLoading(false);
                    return;
                }
                setProgram(fetchedSchedule);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching program schedule:', error);
                setLoading(false);
            }
        };

        fetchProgram();
    }, [scheduleId]);

    // Fetch Templates
    useEffect(() => {
        const fetchTemplates = async () => {
            const trainerId = localStorage.getItem('userId');
            try {
                const fetchedTemplates = await fetchWorkoutTemplatesForTrainer(trainerId);
                const programReservedTemplates = fetchedTemplates.filter(template => template.ProgramReserved === programId);
                const universalTemplates = fetchedTemplates.filter(template => template.ProgramReserved === null);
                setTemplates(fetchedTemplates);
                setProgramReservedTemplates(programReservedTemplates);
                setUniversalTemplates(universalTemplates);
                console.log('Templates:', fetchedTemplates);
                console.log('Program Reserved Templates:', programReservedTemplates);
                console.log('Universal Templates:', universalTemplates);
            } catch (err) {
                console.error('Failed to fetch templates:', err);
            }
        };

        fetchTemplates();
    }, [programId]);

    const handleSubmit = async () => {
        if (!program || program.length === 0) return;
        const { ClientID, ProgramID, StartDate, EndDate } = program[0];
        for (const key in selectedDays) {
            const dayOfWeek = daysOfWeek.indexOf(selectedDays[key]);
            const templateId = selectedTemplates[key];
            if (dayOfWeek === -1 || !templateId) continue;
    
            const occurrences = getDatesOnSpecificDayOfWeek(new Date(StartDate), new Date(EndDate), dayOfWeek);
            console.log(`Occurrences for ${daysOfWeek[dayOfWeek]} between ${StartDate} and ${EndDate}:`, occurrences.map(date => date.toISOString().slice(0, 10)));
    
            for (let date of occurrences) {
                // Format date as YYYY-MM-DD
                const formattedDate = date.toISOString().split('T')[0];
                console.log(`Inserting for ${formattedDate}: ProgramID ${ProgramID}, TemplateID ${templateId}, ClientID ${ClientID}`);
                try {
                    const response = await addProgramDetail(ProgramID, templateId, ClientID, formattedDate);
                    console.log('Program detail created:', response);
                    navigate('/clients');
                } catch (error) {
                    console.error('Failed to add program detail:', error);
                }
            }
        }
    };
    

    function getDatesOnSpecificDayOfWeek(startDate, endDate, dayOfWeek) {
        let dates = [];
        let date = new Date(startDate);
        date.setDate(date.getDate() + ((dayOfWeek + 7 - date.getDay()) % 7));
        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 7);
        }
        return dates;
    }


    const handleCreateNewTemplate = () => {
        navigate('/template-creation', { state: { programId: programId, scheduleId: scheduleId } });
    };

    if (loading || !program) {
        return <h1>Loading...</h1>;
    }

    return (
        <div>
            <h1>Program: {program[0].ProgramID}</h1>
            <h2>Select Template and Day for each program day</h2>
            <button onClick={handleCreateNewTemplate}>Create New Template</button>
            {Array.from({ length: Number(program[0].Recurrence) }).map((_, index) => (
                <div key={index}>
                    <label>Day {index + 1}: </label>
                    <select
                        value={selectedTemplates[index] || ''}
                        onChange={e => setSelectedTemplates(prev => ({ ...prev, [index]: e.target.value }))}
                    >
                        <option value="">Select a template</option>
                        <option disabled>Program Reserved Templates</option>
                        {programReservedTemplates.map(template => (
                            <option key={template.TemplateID} value={template.TemplateID}>
                                {template.Name}
                            </option>
                        ))}
                        <option disabled>──────────</option>
                        <option disabled>Universal Templates</option>
                        {universalTemplates.map(template => (
                            <option key={template.TemplateID} value={template.TemplateID}>
                                {template.Name}
                            </option>
                        ))}
                    </select>
                    <select
                        value={selectedDays[index] || ''}
                        onChange={e => setSelectedDays(prev => ({ ...prev, [index]: e.target.value }))}
                    >
                        <option value="">Select a day</option>
                        {daysOfWeek.map(day => (
                            <option key={day} value={day}>
                                {day}
                            </option>
                        ))}
                    </select>
                    
                </div>
            ))}
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};

export default ProgramDetails;