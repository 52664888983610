// WorkoutSession.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    fetchSpecificWorkoutTemplate,
    fetchTemplateExercises,
    fetchExercise,
    deleteWorkoutTemplate
} from '../../api/apiHandlers';

const Template = () => {
    // Inside your component
    const { sessionId, clientId } = useParams();
    const [sessionDetails, setSessionDetails] = useState(null);
    const navigate = useNavigate();
    const { templateId } = useParams();
    const [templateDetails, setTemplateDetails] = useState(null);
    const [templateExercises, setTemplateExercises] = useState(null);
    const isSuperset = (exercise, exercises) => {
        return exercises.some(e => e.SupersetID === exercise.SupersetID && e.ExerciseID !== exercise.ExerciseID);
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

    useEffect(() => {
        const fetchDetailsAndExercises = async () => {
            try {
                const details = await fetchSpecificWorkoutTemplate(templateId);
                setTemplateDetails(details);
                console.log('Template detail:', details);

                // Fetch exercises for the template
                const exercises = await fetchTemplateExercises(templateId);

                // Sort the exercises based on the OrderID
                const sortedExerciseDetails = exercises.sort((a, b) => a.OrderID - b.OrderID);

                // Group the exercises by OrderID and ExerciseID
                const groupedExercises = sortedExerciseDetails.reduce((acc, exercise) => {
                    const key = `${exercise.OrderID}-${exercise.ExerciseID}`;
                    if (!acc[key]) {
                        acc[key] = {
                            ...exercise,
                            sets: [{ SetNumber: exercise.SetNumber, Reps: exercise.Reps, Notes: exercise.Notes }]
                        };
                    } else {
                        acc[key].sets.push({ SetNumber: exercise.SetNumber, Reps: exercise.Reps, Notes: exercise.Notes });
                    }
                    return acc;
                }, {});

                // Convert the grouped exercises object back to an array
                const groupedExerciseArray = Object.values(groupedExercises);

                // Fetch details for each exercise
                const exerciseDetails = await Promise.all(groupedExerciseArray.map(exercise => fetchExercise(exercise.ExerciseID)));

                // Merge the exercise details with the grouped exercises
                const mergedExercises = groupedExerciseArray.map((exercise, index) => {
                    const sortedSets = exercise.sets.sort((a, b) => a.SetNumber - b.SetNumber);
                    return {
                        ...exercise,
                        sets: sortedSets,
                        Name: exerciseDetails[index].Name,
                        Description: exerciseDetails[index].Description,
                    };
                });

                setTemplateExercises(mergedExercises);
                console.log('Merged exercises:', mergedExercises);

            } catch (error) {
                console.error('Failed to fetch template details or exercises:', error);
            }
        };

        fetchDetailsAndExercises();
    }, [templateId]);


    const handleDeleteTemplate = async templateId => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            try {
                await deleteWorkoutTemplate(templateId);
                
                navigate('/workout-templates');

            } catch (error) {
                console.error('Failed to delete workout template:', error);
            }
        };
    };

    const updateWorkoutTemplate = (templateId) => {
       navigate (`/edit-template/${templateId}`);
    }

    const handleDuplicateTemplate = (templateId) => {
        // we will finish this function later
    }




    if (!templateDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="template-container">
                <h2 className="header"> {templateDetails.Name}</h2>
                <p className="template-description"><strong>Description: </strong>{templateDetails.Description}</p>

                {templateExercises && templateExercises.map((exercise, index) => {
                    if (exercise.SupersetID && templateExercises.findIndex(e => e.SupersetID === exercise.SupersetID) === index) {
                        // This is the first exercise in the superset
                        const exercisesInSuperset = templateExercises.filter(e => e.SupersetID === exercise.SupersetID);
                        return (
                            <div key={`superset-${exercise.SupersetID}`} className="superset-container">
                                <h3 className="superset-header">Superset</h3>
                                {exercisesInSuperset.map(supersetExercise => (
                                    <div key={supersetExercise.ExerciseID} className="exercise-container">
                                        <h4 className="exercise-header">{supersetExercise.Name}</h4>
                                        <p className="exercise-details">{exercise.Description ? exercise.Description : 'No description is set for this exercise.'}</p>
                                        <table className="workout-table">
                                            <thead>
                                                <tr>
                                                    <th>Set</th>
                                                    <th>Reps</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {supersetExercise.sets.map((set, index) => (
                                                    <tr key={index}>
                                                        <td>{set.SetNumber}</td>
                                                        <td>{set.Reps}</td>
                                                        <td>{set.Notes}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>
                        );                        
                    } else if (!exercise.SupersetID) {
                        // This is a standalone exercise
                        return (
                            <div key={exercise.ExerciseID} className="exercise-container">
                                <h4 className="exercise-header">{exercise.Name}</h4>
                                <p className="exercise-details">{exercise.Description ? exercise.Description : 'No description is set for this exercise.'}</p>
                                <table className="workout-table">
                                    <thead>
                                        <tr>
                                            <th>Set</th>
                                            <th>Reps</th>
                                            <th>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {exercise.sets.map((set, index) => (
                                            <tr key={index}>
                                                <td>{set.SetNumber}</td>
                                                <td>{set.Reps}</td>
                                                <td>{set.Notes}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        );                      
                    }
                    // If it is not the first exercise of a superset, we don't render it again.
                    return null;
                })}


                <button onClick={() => handleDeleteTemplate(templateId)}>Delete Template</button>
                <button onClick={() => updateWorkoutTemplate(templateId)}>Update Template</button>
            </div>

            {isModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="modal-close-btn" onClick={() => setIsModalVisible(false)}>&times;</button>
                        <form className="modal-form" onSubmit={(e) => { e.preventDefault(); handleDuplicateTemplate(templateDetails.TemplateID); }}>
                            <h2>Select a date for the duplicated template</h2>
                            <label>
                                Date:
                                <input
                                    type="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    required
                                    className="modal-form-input" // Apply the same input field styling
                                />
                            </label>
                            <button type="submit" className="button">Duplicate</button>
                            <button type="button" className="button" onClick={() => setIsModalVisible(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Template;
