import React, { useState, useEffect } from 'react';
import { registerUser, createClientProfile, fetchTrainers } from '../../api/apiHandlers';
import { useNavigate } from 'react-router-dom';

const CreateClient = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [trainers, setTrainers] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState('');

    const handleRegister = async () => {
        try {
            // Step 1: Register the user account
            const userType = 'client';
            const registerResponse = await registerUser(email, password, userType, firstName, lastName);

            // Check if registration was successful and we have a userId
            if (registerResponse && registerResponse.userId) {
                const userId = registerResponse.userId;

                // Step 2: Create the client profile
                const trainerUserId = localStorage.getItem('userId'); // Get the trainer's ID from localStorage
                const clientData = {
                    UserID: userId,
                    FirstName: firstName,
                    LastName: lastName,
                    TrainerUserID: trainerUserId // Use the trainer's ID
                };
                const profileResponse = await createClientProfile(clientData);

                // Redirect to the clients page
                navigate('/clients');
            } else {
                setError('Registration failed, cannot create profile.');
            }
        } catch (err) {
            console.error('Error:', err);
            setError(err.message);
        }
    };


    useEffect(() => {
        const loadTrainers = async () => {
            try {
                const response = await fetchTrainers();
                if (response.success) {
                    setTrainers(response.trainers);

                    // Console log the fetched trainer data
                    console.log('Fetched Trainers:', response.trainers);
                } else {
                    setError('Failed to load trainers.');
                }
            } catch (err) {
                console.error('Error:', err);
                setError('Failed to load trainers.');
            }
        };

        loadTrainers();
    }, []);

    const handleTrainerChange = (e) => {
        setSelectedTrainer(e.target.value);
        // Find the selected trainer in the trainers array and log their details
        const selectedTrainerDetails = trainers.find(trainer => trainer.id.toString() === e.target.value);
        console.log('Selected Trainer:', selectedTrainerDetails);
    };



    return (
        <div className="form-container">
            <h2>Create Client Account</h2>
            {error && <p className="error-message">Error: {error}</p>}
            <div className="form-control">
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
            </div>
            <div className="form-control">
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
            </div>
            <div className="button-container">
                <button className="button" onClick={handleRegister}>Create Client</button>
            </div>
        </div>
    );
};

export default CreateClient;
