// SlideOver.js
import React, { useEffect } from 'react';
import { ExerciseVolumeChart } from '../profile-components/ChartComponents';
import { fetchSessionDetails } from '../../api/apiHandlers';


const ExerciseSlideOver = ({ isSlideOverOpen, toggleSlideOver, clientId, selectedExerciseId, groupedHistory, sessionDetails }) => {

    if (!isSlideOverOpen) {
        return null;
    }




    return (
        <div className="slide-over-container open">
            <button className="modal-close-btn" onClick={toggleSlideOver}>×</button>
            <h3>Exercise History</h3>
            {Object.keys(groupedHistory).map(date => {
                // Filter the exercises for the date to only include finished ones
                const finishedExercises = groupedHistory[date].filter(item => item.IsFinished === 1);

                // If there are no finished exercises for the date, don't render anything
                if (finishedExercises.length === 0) {
                    return null;
                }

                return (
                    <div key={date}>
                        <h4>{date}</h4>
                        <ul>
                            {finishedExercises.map((item, index) => (
                                <li key={index}>
                                    <strong>Set {item.SetNumber}:</strong>
                                    {item.Reps !== null && <span> <strong>{item.Reps}</strong> <span>reps</span></span>}
                                    {item.Weight !== null && <span> at <strong>{item.Weight}</strong> lbs</span>}
                                    {item.Time !== null && <span> for <strong>{item.Time}</strong> seconds</span>}
                                    {item.Distance !== null && <span> covering <strong>{item.Distance}</strong> meters</span>}
                                    {item.Notes !== null && <span> <strong>Notes:</strong> {item.Notes} </span>}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

export default ExerciseSlideOver;