import React, { useState, useEffect } from 'react';
import { fetchClubId, fetchClubTrainers, fetchTrainerSessions, fetchAndCompareTrainerSessions, fetchTrainerClients } from '../../api/apiHandlers';

const AdminHome = () => {
    const [trainers, setTrainers] = useState([]);
    const [trainerSessions, setTrainerSessions] = useState([]);
    const [trainerClients, setTrainerClients] = useState([]);
    const [comparisonData, setComparisonData] = useState({});
    const [timeFrame, setTimeFrame] = useState('week');
    const adminId = localStorage.getItem('userId');
    const [clubPerformance, setClubPerformance] = useState({
        totalCurrentSessions: 0,
        totalPreviousSessions: 0,
        totalIncrease: 0
    });
    const [isLoading, setIsLoading] = useState(true);




    // Fetch Club ID
    useEffect(() => {
        const fetchAndSetClubId = async () => {
            try {
                const response = await fetchClubId(adminId);
                localStorage.setItem('clubId', response.adminClubId); // Store clubId in local storage
            } catch (error) {
                console.error('Error fetching club ID:', error);
            }
        };
        fetchAndSetClubId();
    }, []);


    // Fetch Trainers
    useEffect(() => {
        const fetchTrainers = async () => {
            try {
                const trainers = await fetchClubTrainers(adminId);
                setTrainers(trainers); // Assuming setTrainers can handle an array of trainers
            } catch (error) {
                console.error('Error fetching trainers:', error);
                // Handle error, e.g., by setting state to show an error message
            }
        };

        fetchTrainers();
    }, []); // The empty array ensures this effect runs only once after the initial render


    // Fetch Trainer Sessions
    useEffect(() => {
        if (!trainers || trainers.length === 0) {
            return; // Do nothing if no trainers
        }

        const fetchSessionsForAllTrainers = async () => {
            // Initialize an object to hold sessions for each trainer
            let sessionsForAllTrainers = {};

            for (const trainer of trainers) {
                try {
                    const sessions = await fetchTrainerSessions(trainer.id);
                    console.log(`Trainer sessions for ${trainer.firstName}:`, sessions);
                    // Store sessions in the object, keyed by trainer ID
                    sessionsForAllTrainers[trainer.id] = sessions;
                } catch (error) {
                    console.error(`Error fetching sessions for trainer ${trainer.id}:`, error);
                }
            }

            // Update state with all sessions
            setTrainerSessions(sessionsForAllTrainers);
        };

        fetchSessionsForAllTrainers();
    }, [trainers]); // This effect runs whenever the trainers state changes


    // Fetch and compare sessions based on the selected timeframe
    useEffect(() => {
        if (!trainers || trainers.length === 0) {
            return; // Do nothing if no trainers
        }

        const fetchAndCompareSessions = async () => {
            let comparisonResults = {};

            for (const trainer of trainers) {
                try {
                    const comparison = await fetchAndCompareTrainerSessions(trainer.id, timeFrame);
                    comparisonResults[trainer.id] = comparison;
                } catch (error) {
                    console.error(`Error fetching and comparing sessions for trainer ${trainer.id}:`, error);
                }
            }

            setComparisonData(comparisonResults);
        };
        fetchAndCompareSessions();
        console.log('Comparison Data:', timeFrame, comparisonData)
    }, [trainers, timeFrame]); // Re-run when trainers or timeframe changes

    // Fetch Trainer Clients
    useEffect(() => {
        // Same as before
    }, [trainers]);



    // Fetch Trainer Clients
    useEffect(() => {
        // Fetch Clients for each Trainer
        if (!trainers || trainers.length === 0) {
            return; // Do nothing if no trainers
        }

        const fetchClientsForAllTrainers = async () => {
            // Initialize an object to hold clients for each trainer
            let clientsForAllTrainers = {};

            for (const trainer of trainers) {
                try {
                    const clients = await fetchTrainerClients(trainer.id);
                    // Store clients in the object, keyed by trainer ID
                    clientsForAllTrainers[trainer.id] = clients;
                } catch (error) {
                    console.error(`Error fetching clients for trainer ${trainer.id}:`, error);
                }
            }

            // Update state with all clients
            setTrainerClients(clientsForAllTrainers);
        }

        fetchClientsForAllTrainers();
    }, [trainers]); // This effect runs whenever the trainers state changes


    // Calculate Club Performance 
    useEffect(() => {
        if (Object.keys(comparisonData).length > 0) {
            let totalCurrent = 0;
            let totalPrevious = 0;

            for (const trainerId in comparisonData) {
                const trainerData = comparisonData[trainerId];
                totalCurrent += trainerData.current[0].TotalSessions;
                totalPrevious += trainerData.previous[0].TotalSessions;
            }

            const totalIncrease = totalCurrent - totalPrevious;

            setClubPerformance({
                totalCurrentSessions: totalCurrent,
                totalPreviousSessions: totalPrevious,
                totalIncrease: totalIncrease
            });

            setIsLoading(false);
        }
    }, [comparisonData]);








    return (
        <div className="container">
            <h1 className="header">Club Performance</h1>
            {/* Dropdown for selecting the timeframe */}
            <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="ytd">YTD</option>
            </select>
            <div className="admin-card-item">
                <h2>Club Total Sessions</h2>
                <div className-="admin-card-info">
                    <p>This {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}: {isLoading ? 'Loading...' : clubPerformance.totalCurrentSessions}</p>
                    <p>Last {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}: {isLoading ? 'Loading...' : clubPerformance.totalPreviousSessions}</p>
                    <p>Increase/Decrease: {isLoading ? 'Loading...' : clubPerformance.totalIncrease}</p>
                </div>
            </div>
            {trainers.length > 0 ? (
                <div className="admin-card-list">
                    {trainers.map(trainer => (
                        <div key={trainer.id} className="admin-card-item">
                            <div className="admin-card-info">
                                <h2>{trainer.firstName} {trainer.lastName}</h2>
                                {/* Display Sessions for the selected timeframe */}
                                <div>
                                    {timeFrame === 'ytd'
                                        ? 'YTD'
                                        : `This ${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}`}: {
                                        comparisonData[trainer.id]?.current[0]?.TotalSessions !== undefined
                                            ? comparisonData[trainer.id]?.current[0]?.TotalSessions
                                            : 'Loading...'
                                    }
                                </div>
                                {/* Display Sessions for the previous timeframe */}
                                <div>
                                    {timeFrame === 'ytd'
                                        ? 'Last YTD'
                                        : `Last ${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}`}: {
                                        comparisonData[trainer.id]?.previous[0]?.TotalSessions !== undefined
                                            ? comparisonData[trainer.id]?.previous[0]?.TotalSessions
                                            : 'Loading...'
                                    }
                                </div>

                                {/* Display Session Increase Compared to Last Timeframe */}
                                <div>
                                    Increase/Decrease: {
                                        comparisonData[trainer.id] === undefined
                                            ? 'Loading...'
                                            : comparisonData[trainer.id]?.comparison?.increase || 'No change'
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="admin-progress-container">
                    <progress className="progress-bar" />
                </div>

            )}
        </div>
    );
};

export default AdminHome;
