import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchSpecificWorkoutProgram, fetchProgramDetails, fetchProgramScheduleProgramId, fetchSpecificWorkoutTemplate } from '../../api/apiHandlers';

const ProgramTemplateView = () => {

    const location = useLocation();
    const programId = location.state?.programId;
    const [program, setProgram] = useState(null);
    const [programDetails, setProgramDetails] = useState(null);
    const [programSchedule, setProgramSchedule] = useState(null);
    const [loading, setLoading] = useState(true);
    const [programLoaded, setProgramLoaded] = useState(false);
    const [programDetailsLoaded, setProgramDetailsLoaded] = useState(false);
    const [programScheduleLoaded, setProgramScheduleLoaded] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedDays, setSelectedDays] = useState({});
    const navigate = useNavigate();



    // Fetch Program
    useEffect(() => {
        const fetchProgram = async () => {
            try {
                const fetchedProgram = await fetchSpecificWorkoutProgram(programId);
                setProgram(fetchedProgram);
                console.log('Program:', fetchedProgram);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch program:', err);
            }
            setProgramLoaded(true);
        }

        if (programId) {
            fetchProgram();
        }
    }
        , [programId]);

    // Fetch Program Details
    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const details = await fetchProgramScheduleProgramId(programId);
                setProgramDetails(details);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching program schedule:', error);
                setLoading(false);
            }
            setProgramDetailsLoaded(true);
        };

        if (programId) {
            fetchSchedule();
        }

    }, [programId]);

    // Fetch Program Schedule
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const schedule = await fetchProgramDetails(programId);
                setProgramSchedule(schedule);
            } catch (error) {
                console.error(error);
            }
            setProgramScheduleLoaded(true);
        };

        if (programId) {
            fetchDetails();
        }
    }, [programId]);

    // Fetch Templates
    useEffect(() => {
        const fetchTemplates = async () => {
            const promises = programSchedule
                .sort((a, b) => new Date(a.ScheduledDate) - new Date(b.ScheduledDate))
                .slice(0, programDetails[0].Recurrence)
                .map(detail => fetchSpecificWorkoutTemplate(detail.TemplateID));
    
            const templates = await Promise.all(promises);
            setTemplates(templates);
        };
    
        if (programLoaded && programDetailsLoaded && programScheduleLoaded) {
            fetchTemplates();
        }
    }, [programLoaded, programDetailsLoaded, programScheduleLoaded, programSchedule, programDetails]);



    useEffect(() => {
        console.log('Program:', program);
        console.log('Details:', programDetails);
        console.log('Schedule:', programSchedule)
        console.log('Templates:', templates);
    }, [programDetails, program]);


    const viewTemplate = template => {
        navigate(`/template/${template.TemplateID}`);
    };


    if (!program || !programDetails || !programSchedule) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <h1>{program.Name}</h1>
            <p>Description: {program.Description}</p>
            <ul>
                {templates.map((template, index) => (
                    <li key={index}>
                        Day {index + 1}: {template.Name}
                        <button onClick={() => viewTemplate(template)}>View Template</button>
                    </li>
                ))}
            </ul>
        </div>
    );

}

export default ProgramTemplateView;