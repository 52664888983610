import React, { useEffect, useState } from 'react';
import { fetchClubId, fetchClubClients, fetchClientTrainer, fetchCompletedSessions, fetchAndCompareClientSessions } from '../../api/apiHandlers';

const AdminClientPerformance = () => {
    const adminId = localStorage.getItem('userId');
    const [clients, setClients] = useState([]);
    const [clientSessions, setClientSessions] = useState([]);
    const [isClubIdFetched, setIsClubIdFetched] = useState(false);
    const [isTrainersFetched, setIsTrainersFetched] = useState(false);
    const [comparisonData, setComparisonData] = useState({});
    const [timeFrame, setTimeFrame] = useState('week');





    // Fetch Club ID
    useEffect(() => {
        const fetchAndSetClubId = async () => {
            try {
                const response = await fetchClubId(adminId);
                localStorage.setItem('clubId', response.adminClubId); // Store clubId in local storage
                setIsClubIdFetched(true); // Indicate that the Club ID has been fetched and stored
            } catch (error) {
                console.error('Error fetching club ID:', error);
            }
        };
        fetchAndSetClubId();
    }, []);



    // Fetch club clients
    useEffect(() => {
        const clubId = localStorage.getItem('clubId');
        if (!clubId) {
            console.error('Club ID not found in local storage');
            return;
        }

        const fetchAndSetClients = async () => {
            try {
                const fetchedClients = await fetchClubClients(clubId);
                setClients(fetchedClients);
            } catch (error) {
                console.error('Error fetching club clients:', error);
            }
        };

        fetchAndSetClients();
    }, [isClubIdFetched]);

    // Fetch client trainers
    useEffect(() => {
        const fetchAndSetTrainers = async () => {
            if (clients.length > 0 && !isTrainersFetched) {
                try {
                    const updatedClients = await Promise.all(clients.map(async (client) => {
                        const trainer = await fetchClientTrainer(client.UserID);
                        return { ...client, trainer: trainer || [] };
                    }));

                    setClients(updatedClients);
                    console.log('Updated clients:', updatedClients);
                    setIsTrainersFetched(true);
                } catch (error) {
                    console.error('Error fetching client trainers:', error);
                }
            }
        };

        fetchAndSetTrainers();
    }, [clients.length, isTrainersFetched]); // Depend on clients.length to avoid refetching trainers when the clients array itself is updated but its length stays the same


    // Fetch workout sessions for each client and calculate total sessions
    useEffect(() => {
        const fetchAndSetWorkoutSessions = async () => {
            if (clients.length > 0 && isTrainersFetched) {
                try {
                    const clientsWithSessionsAndTotal = await Promise.all(clients.map(async (client) => {
                        const workoutSessions = await fetchCompletedSessions(client.UserID);
                        // Calculate total sessions; ensure it's set to a default value if workoutSessions is empty or undefined
                        const totalSessions = workoutSessions ? workoutSessions.length : 0;
                        return { ...client, workoutSessions: workoutSessions || [], totalSessions: totalSessions };
                    }));

                    setClients(clientsWithSessionsAndTotal);
                    console.log('Clients with workout sessions:', clientsWithSessionsAndTotal);

                } catch (error) {
                    console.error('Error fetching workout sessions:', error);
                }
            }
        };

        fetchAndSetWorkoutSessions();
    }, [clients.length, isTrainersFetched]);


    // Group clients by trainer
    const groupClientsByTrainer = (clients) => {
        const grouped = clients.reduce((acc, client) => {
            const trainerName = client.trainer && client.trainer.length > 0
                ? `${client.trainer[0].firstName} ${client.trainer[0].lastName}`
                : 'No Trainer Assigned';

            if (!acc[trainerName]) {
                acc[trainerName] = {
                    clients: [],
                    totalSessions: 0,
                    totalClients: 0,
                };
            }

            acc[trainerName].clients.push(client);
            // Assuming each client object includes a totalSessions property that's a number
            acc[trainerName].totalSessions += client.totalSessions || 0;
            acc[trainerName].totalClients += 1;

            return acc;
        }, {});

        return grouped;
    };

    const groupedClients = groupClientsByTrainer(clients);


    // Fetcn and compare Client Sessions based on selected timeframe
    useEffect(() => {
        if (!clients || clients.length === 0) {
            return; // Do nothing if no clients
        }
        const fetchAndCompareSessions = async () => {
            let comparisonResults = {};

            for (const client of clients) {
                try {
                    const comparison = await fetchAndCompareClientSessions(client.UserID, timeFrame);
                    comparisonResults[client.UserID] = comparison;
                } catch (error) {
                    console.error(`Error fetching and comparing sessions for client ${client.UserID}:`, error);
                }
            }

            setComparisonData(comparisonResults);
        };
        fetchAndCompareSessions();
        console.log('Comparison Data:', timeFrame, comparisonData)
    }, [clients, timeFrame]); // Re-run when clients or timeframe changes





    // Rendering
    return (
        <div className="container">
            <h1 className="header">Trainer Performance</h1>
            <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="ytd">YTD</option>
            </select>
            {Object.keys(groupedClients).length > 0 ? (
                Object.entries(groupedClients).map(([trainerName, { clients, totalSessions, totalClients }]) => (
                    <div key={trainerName}>
                        <h2>{trainerName !== 'No Trainer Assigned' ? trainerName : 'Loading...'}</h2>
                        {/* Display total sessions and clients for each trainer */}
                        <p>Total Sessions: {totalSessions}</p>
                        <p>Total Clients: {totalClients}</p>
                        <div className="admin-card-list">
                            {clients.map(client => (
                                <div key={client.UserID} className="admin-card-item">
                                    <div className="admin-card-info">
                                        <h3>{client.FirstName} {client.LastName}</h3>
                                        {/* Display Sessions for the selected timeframe */}
                                        <div>
                                            {timeFrame === 'ytd'
                                                ? 'YTD'
                                                : `This ${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}`}: {
                                                comparisonData[client.UserID]?.current[0]?.TotalSessions !== undefined
                                                    ? comparisonData[client.UserID]?.current[0]?.TotalSessions
                                                    : 'Loading...'
                                            }
                                        </div>
                                        {/* Display Sessions for the previous timeframe */}
                                        <div>
                                            {timeFrame === 'ytd'
                                                ? 'Last YTD'
                                                : `Last ${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}`}: {
                                                comparisonData[client.UserID]?.previous[0]?.TotalSessions !== undefined
                                                    ? comparisonData[client.UserID]?.previous[0]?.TotalSessions
                                                    : 'Loading...'
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <div className="admin-progress-container">
                    <progress className="progress-bar" />
                </div>
            )}
        </div>
    );
};

export default AdminClientPerformance;
