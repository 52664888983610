import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createWorkoutProgram } from '../../api/apiHandlers';

const ProgramCreation = () => {
    const [programName, setProgramName] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [trainerId, setTrainerId] = useState(localStorage.getItem('userId'));

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const postData = {
                name: programName,
                description: description,
                trainerId: localStorage.getItem('userId')
            };
            const response = await createWorkoutProgram(postData);
            if (response && response.ProgramID) {
                navigate('/program-schedule', { state: { programId: response.ProgramID } });
                console.log('ProgramID:', response.ProgramID);
            } else {
                throw new Error('Failed to retrieve the ProgramID');
            }
        }
        catch (err) {
            setError('Failed to create workout program. Please try again.');
            console.error(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <h1>Create New Workout Program</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="programName">Program Name:</label>
                    <input
                        id="programName"
                        type="text"
                        value={programName}
                        onChange={(e) => setProgramName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Creating...' : 'Create Program'}
                </button>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
};

export default ProgramCreation;