// TemplateExerciseManagement.js
import React, { useState, useEffect, useRef } from 'react';
import {
    fetchExercises,
    deleteWorkoutSession,
    fetchExerciseHistory,
    fetchExercise,
    fetchMuscleGroups,
    fetchExerciseEquipment,
    addProgramExercisesToTemplate,
    deleteWorkoutTemplate
} from '../../api/apiHandlers';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateExerciseModal from '../tracking-components/CreateExerciseModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExerciseSlideOver from '../tracking-components/ExerciseSlideOver';
import ExerciseDetailsModal from '../tracking-components/ExerciseDetailsModal';
import {
    faArrowUp,
    faArrowDown,
    faPlus,
    faEllipsis,
    faMinusCircle,
    faPlusCircle,
    faEdit,
    faAdd,
    faClone,
    faInfoCircle,
    faChartLine,
    faFilter
} from '@fortawesome/free-solid-svg-icons';

const defaultExercise = {
    name: '',
    inputValue: '',
    sets: [{ reps: '', notes: '' }]
};

const TemplateExerciseManagement = () => {
    const [sessionDetails, setSessionDetails] = useState({ description: "" });
    const [exercises, setExercises] = useState([]);
    const [availableExercises, setAvailableExercises] = useState([]);
    const { sessionId } = useParams();
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
    const [exerciseHistory, setExerciseHistory] = useState([]);
    const [currentSupersetId, setCurrentSupersetId] = useState(1);
    const [newSupersetSize, setNewSupersetSize] = useState(0);
    const [exerciseSearchTerm, setExerciseSearchTerm] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState({});
    const [isCreateExerciseModalOpen, setIsCreateExerciseModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedExerciseId, setSelectedExerciseId] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const location = useLocation();
    const templateId = location.state.templateId;
    const [programId, setProgramId] = useState(location.state?.programId);
    const [scheduleId, setScheduleId] = useState(location.state?.scheduleId);

    console.log('ProgramID:', programId);
    console.log('ScheduleID:', scheduleId);

    const slideOverStyles = {
        position: 'fixed',
        top: 0,
        right: 0,
        width: '300px',
        height: '100%',
        overflowY: 'auto',
        backgroundColor: 'white',
        boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.5)',
        padding: '20px',
        boxSizing: 'border-box',
        transform: 'translateX(100%)',
        transition: 'transform 0.3s ease-in-out',
    };
    const slideOverOpenStyles = {
        transform: 'translateX(0)',
    };
    const [displayExercises, setDisplayExercises] = useState([]);
    const [filters, setFilters] = useState({
        muscleGroup: '',
        equipment: '',
    });

    console.log('TemplateId:', templateId);

    // Reload Exercise Library after closing Modal
    async function handleModalClose() {
        await loadExercises();
    }

    // Load Exercises
    const loadExercises = async () => {
        try {
            const trainerId = localStorage.getItem('userId');
            const exercisesData = await fetchExercises(trainerId);
            exercisesData.sort((a, b) => a.Name.localeCompare(b.Name));
            console.log('Exercises:', exercisesData);

            setAvailableExercises(exercisesData);

        } catch (error) {
            console.error('Error loading exercises:', error);
        }
    };

    useEffect(() => {
        loadExercises();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                const muscleGroupsPromise = fetchMuscleGroups();
                const equipmentPromise = fetchExerciseEquipment();

                const [muscleGroupsData, equipmentData] = await Promise.all([
                    muscleGroupsPromise,
                    equipmentPromise,
                ]);

                setMuscleGroups(muscleGroupsData);
                console.log('Muscle Groups:', muscleGroupsData);
                setEquipment(equipmentData);
                console.log('Equipment:', equipmentData);

            } catch (error) {
                console.error('Error loading data:', error);
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        // Add when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Return a function to be called when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Filter Exercises
    const applyFilters = (exercisesArray = availableExercises) => {
        let filtered = exercisesArray;

        if (filters.muscleGroup) {
            filtered = filtered.filter(exercise => exercise.PrimaryMuscleGroupName === filters.muscleGroup);
        }

        if (filters.equipment) {
            filtered = filtered.filter(exercise => exercise.EquipmentName === filters.equipment);
        }

        setDisplayExercises(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filters, availableExercises]); // Re-apply filters whenever they change or new exercises are loaded

    const handleMuscleGroupChange = (e) => {
        setFilters(prevFilters => ({ ...prevFilters, muscleGroup: e.target.value }));
        applyFilters();
    };

    const handleEquipmentChange = (e) => {
        setFilters(prevFilters => ({ ...prevFilters, equipment: e.target.value }));
        applyFilters();
    };

    // Handle exercise selection
    const handleExerciseSelect = (index, selectedExercise) => {
        const newExercises = [...exercises];
        newExercises[index] = {
            ...newExercises[index],
            id: selectedExercise.ExerciseID,
            name: selectedExercise.Name,
            inputValue: selectedExercise.Name,
            sets: newExercises[index].sets // Ensure sets are preserved
        };
        setExercises(newExercises);
        console.log('Selected Exercise:', selectedExercise);
        setIsDropdownVisible({ ...isDropdownVisible, [index]: false }); // Hide the dropdown
        setSelectedExerciseId(selectedExercise.ExerciseID);
    };

    const addExercise = () => {
        const newExercise = {
            ...defaultExercise,
            inputValue: '', // Ensure the inputValue is an empty string
            order: exercises.length + 1, // Assign the next OrderID
            supersetId: null, // Assign a default supersetId
            sets: [], // Ensure sets is an empty array
        };

        setExercises([...exercises, newExercise]);
    };

    const removeExercise = (index) => {
        const newExercises = exercises.filter((_, i) => i !== index);
        setExercises(newExercises);
    };

    // Set Management
    const addSet = (exerciseIndex) => {
        const newExercises = [...exercises];
        newExercises[exerciseIndex].sets.push({ reps: '', notes: '' });
        setExercises(newExercises);
    };

    const removeSet = (exerciseIndex, setIndex) => {
        const newExercises = [...exercises];
        newExercises[exerciseIndex].sets = newExercises[exerciseIndex].sets.filter((_, i) => i !== setIndex);
        setExercises(newExercises);
    };

    const copySet = (exerciseIndex, setIndex) => {
        const setToCopy = exercises[exerciseIndex].sets[setIndex];
        const newExercises = [...exercises];
        newExercises[exerciseIndex].sets.push({ ...setToCopy });
        setExercises(newExercises);
    };

    const handleAddNotes = (exerciseIndex, setIndex) => {
        // Retrieve the current notes or an empty string if none exist.
        const currentNotes = exercises[exerciseIndex].sets[setIndex].notes || '';
        const note = prompt("Enter your notes:", currentNotes);
        if (note !== null && note !== currentNotes) { // Proceed only if the prompt wasn't cancelled and there's a change.
            setExercises(prevExercises => {
                const newExercises = [...prevExercises];
                newExercises[exerciseIndex].sets[setIndex].notes = note;
                return newExercises;
            });
        }
    };

    // Prepare exercises for saving
    function prepareExercisesForSave() {
        return exercises.flatMap((exercise, index) => 
            exercise.sets.map((set, setIndex) => ({
                exerciseId: exercise.id,
                supersetId: exercise.supersetId || null,
                orderId: index + 1,
                setNumber: setIndex + 1,
                notes: set.notes,
                reps: set.reps
            }))
        );
    }

    const handleSubmit = async (event) => {
        console.log('handleSubmit called');
        event.preventDefault();
        setIsSaving(true);
        try {
            const preparedExercises = prepareExercisesForSave();
            console.log('Prepared Exercises:', preparedExercises);

            await addProgramExercisesToTemplate(templateId, preparedExercises);

            if (programId !== undefined) {
                navigate('/program-details', { state: { programId: programId, scheduleId: scheduleId } });
            } else {
                navigate('/workout-templates');
            }

        } catch (error) {
            console.error('Error in saving or finishing workout session:', error);

        } finally {
            setIsSaving(false);
        }
    };

    const handleDiscardTemplate = () => {
        if (window.confirm('Are you sure you want to delete this workout?')) {
            deleteWorkoutTemplate(templateId)
                .then(() => {
                    navigate('/workout-templates');
                })
                .catch(err => console.error(err));
        }
    };

    const toggleSlideOver = async (exerciseId) => {
        if (!isSlideOverOpen) {
            await loadExerciseHistory(exerciseId);
        }
        setIsSlideOverOpen(!isSlideOverOpen);
    };

    // Fetch Exercise History
    const loadExerciseHistory = async (exerciseId) => {
        try {
            const historyData = await fetchExerciseHistory(clientId, exerciseId);
            setExerciseHistory(historyData);
        } catch (err) {
            console.error('Error loading exercise history:', err);
        }
    };

    const groupByDate = (history) => {
        return history.reduce((acc, item) => {
            const date = new Date(item.Date).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});
    };

    const groupedHistory = groupByDate(exerciseHistory);

    // Create a new superset
    const handleCreateSuperset = () => {
        const size = prompt("How many exercises to add to the superset?");
        if (size && !isNaN(size) && Number(size) > 0) {
            const supersetSize = Number(size);
            const supersetExercises = Array.from({ length: supersetSize }, (_, i) => ({
                ...defaultExercise,
                supersetId: currentSupersetId, // Use the currentSupersetId
                order: exercises.length + i + 1 // Assign the next OrderID
            }));

            // Add the new superset exercises to the existing ones
            setExercises([...exercises, ...supersetExercises]);
            // Increment the currentSupersetId to ensure the next one is unique
            setCurrentSupersetId(currentSupersetId + 1);
        } else {
            alert("Please enter a valid number.");
        }
    };

    const moveExerciseUp = (index) => {
        // Can't move up the first item
        if (index === 0) return;

        setExercises(prevExercises => {
            const newExercises = [...prevExercises];
            const currentExercise = newExercises[index];
            const prevExercise = newExercises[index - 1];

            if (currentExercise.supersetId !== null) {
                if (currentExercise.supersetId !== prevExercise.supersetId) {
                    return newExercises;
                } else {
                    // Directly swap the current and previous exercises without removing and inserting
                    [newExercises[index], newExercises[index - 1]] = [newExercises[index - 1], newExercises[index]];
                }
                return newExercises;
            } else {
                // Check whether prevExercise.supersetId is null or not
                if (prevExercise.supersetId === null) {
                    [currentExercise.order, prevExercise.order] = [prevExercise.order, currentExercise.order];
                    [newExercises[index], newExercises[index - 1]] = [newExercises[index - 1], newExercises[index]];
                } else {
                    const supersetExercises = newExercises.filter(exercise => exercise.supersetId === prevExercise.supersetId);
                    const firstSupersetExercise = supersetExercises.reduce((first, exercise) => {
                        return (first && first.order < exercise.order) ? first : exercise;
                    }, null);

                    currentExercise.order = firstSupersetExercise.order;
                    newExercises.splice(index, 1);
                    newExercises.splice(newExercises.indexOf(firstSupersetExercise), 0, currentExercise);

                    newExercises.forEach((exercise, i) => {
                        if (i <= newExercises.indexOf(currentExercise)) {
                            exercise.order = i + 1;
                        }
                    });
                }

                newExercises.forEach((exercise, i) => {
                    exercise.order = i + 1;
                });
            }

            return newExercises;
        });
    };

    const moveExerciseDown = (index) => {
        // Can't move down the last item
        if (index === exercises.length - 1) return;

        setExercises(prevExercises => {
            const newExercises = [...prevExercises];
            const currentExercise = newExercises[index];
            const nextExercise = newExercises[index + 1];

            if (currentExercise.supersetId !== null) {
                if (currentExercise.supersetId !== nextExercise.supersetId) {
                    return newExercises;
                } else {
                    [newExercises[index], newExercises[index + 1]] = [newExercises[index + 1], newExercises[index]];
                }
                return newExercises;
            } else {
                if (nextExercise.supersetId === null) {
                    [currentExercise.order, nextExercise.order] = [nextExercise.order, currentExercise.order];
                    [newExercises[index], newExercises[index + 1]] = [newExercises[index + 1], newExercises[index]];
                } else {
                    const supersetExercises = newExercises.filter(exercise => exercise.supersetId === nextExercise.supersetId);
                    const lastSupersetExercise = supersetExercises.reduce((last, exercise) => {
                        return (last && last.order > exercise.order) ? last : exercise;
                    }, null);

                    currentExercise.order = lastSupersetExercise.order;
                    newExercises.splice(index, 1);
                    newExercises.splice(newExercises.indexOf(lastSupersetExercise) + 1, 0, currentExercise);

                    newExercises.forEach((exercise, i) => {
                        if (i >= newExercises.indexOf(currentExercise)) {
                            exercise.order = i + 1;
                        }
                    });
                }

                newExercises.forEach((exercise, i) => {
                    exercise.order = i + 1;
                });
            }

            return newExercises;
        });
    };

    // Keep track of the last superset ID
    let lastSupersetId = null;

    // Handle exercise search
    const filterExercises = (inputValue) => {
        let filteredByInput = availableExercises;

        if (inputValue.trim()) {
            filteredByInput = availableExercises.filter(exercise =>
                exercise.Name.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        if (filters.muscleGroup) {
            filteredByInput = filteredByInput.filter(exercise => exercise.PrimaryMuscleGroupName === filters.muscleGroup);
        }

        if (filters.equipment) {
            filteredByInput = filteredByInput.filter(exercise => exercise.EquipmentName === filters.equipment);
        }

        return filteredByInput;
    };

    // Handle exercise input change
    const handleExerciseInputChange = (index, value) => {
        const newExercises = [...exercises];
        newExercises[index].inputValue = value;
        setExercises(newExercises);
    };

    // Toggle the Modal State
    const toggleCreateExerciseModal = () => {
        setIsCreateExerciseModalOpen(!isCreateExerciseModalOpen);
    };

    const handleUserInteraction = (action) => (event) => {
        if (event.type === 'click' && event.detail === 0) {
            return;
        }
        action(event);
    };

    // Dynamically Generate Input Fields for Exercises
    const renderExerciseInputs = (exercise, exerciseIndex, setIndex) => {
        return (
            <>
                {exercise && (
                    <input
                        type="number"
                        className="set-input"
                        value={exercise.sets[setIndex].reps}
                        onChange={(e) => handleSetChange(exerciseIndex, setIndex, 'reps', e.target.value)}
                        placeholder="Reps"
                    />
                )}
            </>
        );
    };

    // Handle exercise set change
    const handleSetChange = (exerciseIndex, setIndex, field, value) => {
        setExercises(prevExercises => {
            const newExercises = [...prevExercises];
            newExercises[exerciseIndex].sets[setIndex][field] = value;
            return newExercises;
        });
    };

    // Ellipsis Menu Logic
    const menuRef = useRef();
    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, setIsMenuVisible]);

    // Superset Logic
    const addExerciseToSuperset = (supersetId) => {
        const selectedSuperset = exercises.filter((exercise) => exercise.supersetId === supersetId);
        const maxOrder = Math.max(...selectedSuperset.map(exercise => exercise.order));
        const newExercise = { ...defaultExercise, supersetId: supersetId, sets: [{ reps: '', notes: '' }], order: maxOrder + 1 };

        let updatedExercises = [...exercises, newExercise];

        updatedExercises = updatedExercises.map((exercise) => {
            if (exercise.order >= newExercise.order && exercise !== newExercise) {
                return { ...exercise, order: exercise.order + 1 };
            }
            return exercise;
        });

        updatedExercises.sort((a, b) => a.order - b.order);

        setExercises(updatedExercises);
    };

    const moveSupersetUp = (supersetId) => {
        setExercises((prevExercises) => {
            const exercises = [...prevExercises];
            const startIndex = exercises.findIndex(ex => ex.supersetId === supersetId);

            if (startIndex <= 0) {
                return exercises;
            }

            let endIndex = startIndex;
            while (endIndex < exercises.length - 1 && exercises[endIndex + 1].supersetId === supersetId) {
                endIndex++;
            }

            let prevIndex = startIndex - 1;
            if (prevIndex > 0 && exercises[prevIndex].supersetId !== null) {
                while (prevIndex > 0 && exercises[prevIndex - 1].supersetId === exercises[prevIndex].supersetId) {
                    prevIndex--;
                }
            }

            if (exercises[prevIndex].supersetId === null) {
                const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
                exercises.splice(prevIndex, 0, ...superset);
                return exercises;
            }

            let prevSupersetStartIndex = prevIndex;
            while (prevSupersetStartIndex > 0 && exercises[prevSupersetStartIndex - 1].supersetId === exercises[prevSupersetStartIndex].supersetId) {
                prevSupersetStartIndex--;
            }

            const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
            exercises.splice(prevSupersetStartIndex, 0, ...superset);

            exercises.forEach((exercise, index) => {
                exercise.order = index + 1;
            });

            return exercises;
        });
    };

    const moveSupersetDown = (supersetId) => {
        setExercises((prevExercises) => {
            const exercises = [...prevExercises];
            const startIndex = exercises.findIndex(ex => ex.supersetId === supersetId);

            if (startIndex < 0 || startIndex >= exercises.length - 1) {
                return exercises;
            }

            let endIndex = startIndex;
            while (endIndex < exercises.length - 1 && exercises[endIndex + 1].supersetId === supersetId) {
                endIndex++;
            }

            let nextIndex = endIndex + 1;
            if (nextIndex < exercises.length && exercises[nextIndex].supersetId !== null) {
                while (nextIndex < exercises.length - 1 && exercises[nextIndex + 1].supersetId === exercises[nextIndex].supersetId) {
                    nextIndex++;
                }
            }

            if (exercises[nextIndex] && exercises[nextIndex].supersetId === null) {
                const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
                exercises.splice(nextIndex - superset.length + 1, 0, ...superset);
                return exercises;
            }

            let nextSupersetEndIndex = nextIndex;
            while (nextSupersetEndIndex < exercises.length - 1 && exercises[nextSupersetEndIndex + 1].supersetId === exercises[nextSupersetEndIndex].supersetId) {
                nextSupersetEndIndex++;
            }

            const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
            exercises.splice(nextSupersetEndIndex - superset.length + 1, 0, ...superset);

            exercises.forEach((exercise, index) => {
                exercise.order = index + 1;
            });

            return exercises;
        });
    };

    const handleSelectExercise = (event, exercise) => {
        event.preventDefault();
        const fullExerciseDetails = availableExercises.find(ex => ex.ExerciseID === exercise.id);
        if (!fullExerciseDetails) {
            console.error("Exercise details not found for id:", exercise.id);
            return;
        }

        const transformedExercise = {
            ExerciseID: fullExerciseDetails.ExerciseID,
            Name: fullExerciseDetails.Name,
            Description: fullExerciseDetails.Description || "",
            EquipmentName: fullExerciseDetails.EquipmentName,
            PrimaryMuscleGroupName: fullExerciseDetails.PrimaryMuscleGroupName,
            AssistingMuscleGroupName: fullExerciseDetails.AssistingMuscleGroupName,
            TracksDistance: fullExerciseDetails.TracksDistance,
            TracksReps: fullExerciseDetails.TracksReps,
            TracksTime: fullExerciseDetails.TracksTime,
            TracksWeight: fullExerciseDetails.TracksWeight,
            TrainerUserID: fullExerciseDetails.TrainerUserID,
            isBilateral: fullExerciseDetails.isBilateral,
            isCardio: fullExerciseDetails.isCardio,
            isUnilateral: fullExerciseDetails.isUnilateral,
            file_url: fullExerciseDetails.file_url
        };

        setSelectedExercise(transformedExercise);
        setIsDetailsModalOpen(true);
    };

    const toggleDetailsModal = async () => {
        if (isDetailsModalOpen && selectedExerciseId) {
            await refreshExerciseDetails(selectedExerciseId);
        }
        setIsDetailsModalOpen(prev => !prev);
    };

    const refreshExerciseDetails = async (exerciseId) => {
        console.log(`Starting to refresh details for exerciseId: ${exerciseId}`);
        try {
            const updatedExerciseDetails = await fetchExercise(exerciseId);
            console.log('Fetched updated exercise details:', updatedExerciseDetails);
            handleExerciseUpdated(updatedExerciseDetails);
        } catch (error) {
            console.error('Error refreshing exercise details:', error);
        }
    };

    const handleExerciseUpdated = (updatedExercise) => {
        setExercises((prevExercises) =>
            prevExercises.map((exercise) =>
                exercise.id === updatedExercise.ExerciseID ? { ...exercise, ...updatedExercise, inputValue: updatedExercise.Name } : exercise
            )
        );
        loadExercises();
    };

    function handleClickOutside(event) {
        if (event.target.closest('.exercise-selector')) {
            const exerciseElement = event.target.closest('.exercise-selector');
            const exerciseId = exerciseElement.getAttribute('data-exercise-index');
            setIsDropdownVisible(prevState => {
                const newState = { ...prevState };
                Object.keys(newState).forEach(key => {
                    newState[key] = key === exerciseId ? prevState[key] : false;
                });
                return newState;
            });
        } else {
            setIsDropdownVisible(prevState => {
                const newState = { ...prevState };
                Object.keys(newState).forEach(key => {
                    newState[key] = false;
                });
                return newState;
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="track-workout-container">
                <div className="main-workout-view">
                    <h2>Create Workout Template</h2>
                    <div className="session-details"></div>
                    {exercises.reduce((acc, exercise, exerciseIndex) => {
                        let supersetHeading = null;
                        let isStartOfSuperset = exercise.supersetId && exercise.supersetId !== lastSupersetId;
                        let addExerciseButton = null;
                        let moveSupersetUpButton = null;
                        let moveSupersetDownButton = null;

                        if (isStartOfSuperset) {
                            supersetHeading = <h3 className="superset-header">Superset</h3>;
                            lastSupersetId = exercise.supersetId;

                            addExerciseButton = (
                                <button className="track-workout-button" onClick={(event) => {
                                    event.preventDefault();
                                    addExerciseToSuperset(exercise.supersetId);
                                }}> <FontAwesomeIcon icon={faAdd} /> Add Exercise </button>
                            );

                            moveSupersetUpButton = (
                                <button className="track-workout-button" onClick={(event) => {
                                    event.preventDefault();
                                    moveSupersetUp(exercise.supersetId);
                                }}> Move Superset <FontAwesomeIcon icon={faArrowUp} /></button>
                            );

                            moveSupersetDownButton = (
                                <button className="track-workout-button" onClick={(event) => {
                                    event.preventDefault();
                                    moveSupersetDown(exercise.supersetId); // Call the moveSupersetDown function
                                }}>Move Superset <FontAwesomeIcon icon={faArrowDown} /></button>
                            );
                        }

                        const exerciseElement = (
                            <div key={exerciseIndex} className={`exercise-section ${exercise.supersetId ? 'superset-exercise' : ''}`}>
                                {supersetHeading}
                                <div className="superset-controls"> {/* A div to group superset-related buttons */}
                                    {addExerciseButton}
                                    {moveSupersetUpButton}
                                    {moveSupersetDownButton}
                                </div>
                                <div data-exercise-index={exerciseIndex} className="exercise-selector">
                                    <div className="exercise-input-info-container">
                                        <input
                                            type="text"
                                            placeholder="Type to search exercises..."
                                            value={exercise.inputValue || ''}
                                            className="exercise-search-input"
                                            onChange={(e) => {
                                                handleExerciseInputChange(exerciseIndex, e.target.value);
                                                setIsDropdownVisible({ ...isDropdownVisible, [exerciseIndex]: true });
                                            }}
                                            onFocus={() => {
                                                setIsDropdownVisible({ ...isDropdownVisible, [exerciseIndex]: true });
                                            }}
                                        />
                                        <button className="exercise-mod-buttons" type="button" onClick={(event) => handleSelectExercise(event, exercise)}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </button>
                                        <button className="exercise-mod-buttons" type="button" onClick={() => toggleSlideOver(exercise.id)}>
                                            <FontAwesomeIcon icon={faChartLine} />
                                        </button>
                                        <button className="exercise-mod-buttons" type="button" onClick={() => removeExercise(exerciseIndex)}>
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </button>
                                    </div>
                                    {isDropdownVisible[exerciseIndex] && (
                                        <div className="exercise-suggestions">
                                            <div className="filter-container">
                                                <div className="filter-dropdown">
                                                    <label>Muscle Group:</label>
                                                    <select id="muscle-group-selector" name="muscleGroup" onChange={handleMuscleGroupChange} value={filters.muscleGroup} onClick={(e) => e.stopPropagation()}>
                                                        <option value="">No filter selected.</option>
                                                        {muscleGroups.map((group) => (
                                                            <option key={group.MuscleGroupID} value={group.MuscleGroupName}>{group.MuscleGroupName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="filter-dropdown">
                                                    <label>Equipment:</label>
                                                    <select id="equipment-selector" name="equipment" onChange={handleEquipmentChange} value={filters.equipment} onClick={(e) => e.stopPropagation()}>
                                                        <option value="">No filter selected.</option>
                                                        {equipment.map((item) => (
                                                            <option key={item.EquipmentID} value={item.EquipmentName}>{item.EquipmentName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <ul>
                                                {filterExercises(exercise.inputValue).map(ex => (
                                                    <li key={ex.ExerciseID} onClick={() => {
                                                        handleExerciseSelect(exerciseIndex, ex);
                                                        setIsDropdownVisible({ ...isDropdownVisible, [exerciseIndex]: false });
                                                    }}>
                                                        {ex.Name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                {exercise.sets.map((set, setIndex) => (
                                    <div key={setIndex} className="set-section">
                                        {renderExerciseInputs(exercise, exerciseIndex, setIndex)}
                                        <button className="exercise-mod-buttons" type="button" onClick={() => removeSet(exerciseIndex, setIndex)}>
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </button>
                                        <button className="exercise-mod-buttons" type="button" onClick={() => copySet(exerciseIndex, setIndex)}>
                                            <FontAwesomeIcon icon={faClone} />
                                        </button>
                                        <FontAwesomeIcon
                                                icon={faEdit}
                                                className={`exercise-mod-buttons ${set.notes ? 'has-notes' : ''}`}
                                                onClick={() => handleAddNotes(exerciseIndex, setIndex)}
                                            />
                                    </div>
                                ))}
                                <div className="exercise-action-buttons">
                                    <button className="exercise-mod-buttons" type="button" onClick={() => addSet(exerciseIndex)}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </button>
                                    <div className="exercise-move-container">
                                        <button className="exercise-move-buttons" type="button" onClick={() => moveExerciseUp(exerciseIndex)}>
                                            <FontAwesomeIcon icon={faArrowUp} />
                                        </button>
                                        <button className="exercise-move-buttons" type="button" onClick={() => moveExerciseDown(exerciseIndex)}>
                                            <FontAwesomeIcon icon={faArrowDown} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );

                        if (isStartOfSuperset) {
                            acc.push(<div key={exerciseIndex} className="superset-container">{[exerciseElement]}</div>);
                        } else {
                            let lastElement = acc[acc.length - 1];
                            if (lastElement && lastElement.props.className === "superset-container" && lastSupersetId === exercise.supersetId) {
                                lastElement.props.children.push(exerciseElement);
                            } else {
                                acc.push(exerciseElement);
                            }
                        }

                        return acc;
                    }, [])}
                </div>
                <div className="track-workout-sticky-buttons">
                    <div>
                        <button className="track-workout-ellipsis" type="button" onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faEllipsis} />
                        </button>
                        {isMenuVisible && (
                            <div className="ellipsis-floating-menu" ref={menuRef}>
                                <button className="track-workout-button" type="button" onClick={() => { toggleCreateExerciseModal(); setIsMenuVisible(false); }}>Create New Exercise</button>
                                <button className="track-workout-button" type="button" onClick={() => { handleCreateSuperset(); setIsMenuVisible(false); }}>Create Superset</button>
                            </div>
                        )}
                    </div>
                    <div className="sticky-center-buttons">
                        {isSaving ? (
                            <progress className="progress-bar" />
                        ) : (
                            <div>
                                <button
                                    className="save-workout-button"
                                    type="submit"
                                    onClick={() => handleUserInteraction(handleSubmit)}
                                >
                                    Save Template
                                </button>
                                <button
                                    className="delete-workout-button"
                                    type="button"
                                    onClick={handleUserInteraction(handleDiscardTemplate)}
                                >
                                    Discard Template
                                </button>
                            </div>
                        )}
                    </div>
                    <button className="add-exercise-button" type="button" onClick={addExercise}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </div>

            <ExerciseSlideOver
                isSlideOverOpen={isSlideOverOpen}
                toggleSlideOver={toggleSlideOver}
                clientId={clientId}
                selectedExerciseId={selectedExerciseId}
                groupedHistory={groupedHistory}
                sessionDetails={sessionDetails}
            />

            <CreateExerciseModal
                isCreateExerciseModalOpen={isCreateExerciseModalOpen}
                toggleCreateExerciseModal={toggleCreateExerciseModal}
                onModalClose={handleModalClose}
            />

            <ExerciseDetailsModal
                isDetailsModalOpen={isDetailsModalOpen}
                toggleDetailsModal={toggleDetailsModal}
                selectedExercise={selectedExercise}
                onModalClose={toggleDetailsModal}
                onExerciseUpdated={handleExerciseUpdated}
            />
        </form>
    );
};

export default TemplateExerciseManagement;
