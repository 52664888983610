// WorkoutProgramLibrary.js

import React, { useEffect, useState } from 'react';
import { fetchWorkoutProgramsForTrainer, fetchProgramScheduleProgramId, fetchClientAccountDetails, deleteWorkoutProgram } from '../../api/apiHandlers';
import { useNavigate } from 'react-router-dom';

const WorkoutProgramLibrary = () => {
    const [workoutPrograms, setWorkoutPrograms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [programId, setProgramId] = useState('');
    const trainerId = localStorage.getItem('userId');
    const [schedules, setSchedules] = useState({});
    const [clientAccounts, setClientAccounts] = useState({});
    const navigate = useNavigate();



    // Fetch Workout Programs on Mount
    useEffect(() => {
        const fetchPrograms = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const programs = await fetchWorkoutProgramsForTrainer(trainerId);
                console.log('Programs:', programs);
                if (Array.isArray(programs)) {
                    setWorkoutPrograms(programs);
                } else {
                    console.error('Programs is not an array:', programs);
                    setWorkoutPrograms([]);
                }
            } catch (err) {
                setError('Failed to fetch workout programs');
                console.error(err);
            }
            setIsLoading(false);
        };

        fetchPrograms();

    }, []);

    // Fetch Program Schedule
    useEffect(() => {
        const fetchSchedulesAndAccounts = async () => {
            setIsLoading(true);
            const newSchedules = {};
            const newClientAccounts = {};
            for (const program of workoutPrograms) {
                try {
                    const fetchedSchedule = await fetchProgramScheduleProgramId(program.ProgramID);
                    console.log('Fetched schedule:', fetchedSchedule);
                    newSchedules[program.ProgramID] = fetchedSchedule;

                    for (const schedule of fetchedSchedule) {
                        if (!newClientAccounts[schedule.ClientID]) {
                            const fetchedAccount = await fetchClientAccountDetails(schedule.ClientID);
                            console.log('Fetched account:', fetchedAccount);
                            newClientAccounts[schedule.ClientID] = fetchedAccount;
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch schedule or account:', error);
                }
            }
            setSchedules(newSchedules);
            setClientAccounts(newClientAccounts);
            setIsLoading(false);
        };

        fetchSchedulesAndAccounts();
    }, [workoutPrograms]);



    // Button Handlers
    const handleCreate = () => {
        navigate('/program-creation');
    };

    const handleView = (programId) => {
        console.log('Sending ProgramID:', programId)
        navigate('/program-template-view', { state: { programId } });
    };

    const handleDelete = async programId => {
        if (window.confirm('Are you sure you want to delete this program?')) {
            try {
                await deleteWorkoutProgram(programId);
                const updatedPrograms = await fetchWorkoutProgramsForTrainer(trainerId);
                setWorkoutPrograms(updatedPrograms);
            } catch (error) {
                console.error('Failed to delete workout program:', error);
            }
        };
    }




    return (
        <div>
            <h1>Workout Programs</h1>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : workoutPrograms.length === 0 ? (
                <p>No workout programs found. Start by creating a new one.</p>
            ) : (
                <ul>
                    {workoutPrograms.map(program => {
                        const schedule = schedules[program.ProgramID];
                        const clientAccount = schedule && clientAccounts[schedule[0].ClientID];
                        return (
                            <li key={program.ProgramID}>
                                {clientAccount && <div>Client: {clientAccount.firstName} {clientAccount.lastName}</div>}
                                {program.Name} - {program.Description}
                                <button onClick={() => handleView(program.ProgramID)}>View</button>
                                <button onClick={() => handleDelete(program.ProgramID)}>Delete</button>
                            </li>
                        );
                    })}
                </ul>
            )}
            <button onClick={handleCreate}>Create New Program</button>
        </div>
    );
};

export default WorkoutProgramLibrary;